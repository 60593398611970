import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function Wills() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="wills"
      className="generic-page"
      title="Wills"
      description="Learn more about a last will and testament and how our attorneys can help you ensure your assets are distributed as you wish"
    >
      <div className="page-content">
        <h1>Wills</h1>
        <p className="subheader">
          A wills attorney can ensure your assets are distributed as you wish
        </p>
        <p>
          A last will and testament is a document that, like a living trust,
          names beneficiaries for property and assets in the event of the
          author's death. It is read by a court of law to ensure that these
          wishes are upheld. A will can also be used to name a guardian for
          children and their property. A standalone will may be a sufficient
          option for clients whose total property value does not exceed
          $150,000. However, estate planning can be complicated, and it is
          recommended that you speak to a lawyer before making these important
          decisions. A wills attorney can help draft a will that best serves
          your estate planning goals and needs. We have helped many clients
          achieve peace of mind knowing that their assets will eventually be
          distributed exactly as they wish.
        </p>

        <h2>Why Do I Need a Will?</h2>
        <p>
          If someone dies without making a will or some other arrangement of
          property transfer, the fate of their property will be determined
          according to state laws. According to these laws, the deceased's
          wealth and assets are generally inherited by their spouse and children
          or by the closest living relative. If the deceased had neither a
          family nor relatives, the estate becomes property of the state.
        </p>
        <p>
          We have helped many clients achieve peace of mind knowing that their
          assets will eventually be distributed exactly as they wish.
        </p>
        <p>
          The attorneys at Deason Garner & Hansen Law Firm encourage clients,
          especially those with substantial assets, to invest in a living trust
          so that their estate does not have to go through a long and expensive
          probate process after their death. However, a will is also recommended
          because it can accomplish things that living trusts cannot. For
          example, a will allows you to name guardians for your minor children.
          Also, a will can be used to cancel debts that are owed to you.
        </p>
        <p>
          Living trusts also include only the property and assets transferred to
          it by the client in writing. If you have not transferred everything
          you own to the trust, unaccounted items will not be covered by its
          terms. Fortunately, if you choose to create a living trust as the
          primary method of estate management, you do not require a complicated
          will. A basic will that specifies who should inherit the property not
          already transferred to the trust will typically account for everything
          the living trust does not.
        </p>
        <p>
          Another example of a basic will that can complement a living trust is
          the “pour-over will,” which specifies that any remaining assets should
          be transferred to the trust after your death. It should be noted that
          this property must still undergo the probate process before being
          included in the trust.
        </p>

        <h2>Schedule a Consultation With an Attorney</h2>
        <p>
          Shawn D. Garner and Adam D. Hansen can help you make certain that your
          assets are distributed precisely as you wish. If you would like to
          discuss your estate planning options in Yuma, Arizona, with one of our
          attorneys, call us at (928) 783-4575 or contact us online to schedule
          a consultation.
        </p>
      </div>
    </Page>
  );
}

export default Wills;
