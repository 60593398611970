import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function ProbateFAQ() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="probate"
      className="generic-page"
      title="Probate FAQ"
      description="Find answers to some of the most common questions regarding probate. Having the right information can save you time and money."
    >
      <div className="page-content">
        <h1>Probate FAQ</h1>
        <p>
          We can help you avoid the serious costs of misunderstanding probate.
          Time, money and stress can be saved with the proper estate plan, and
          the attorneys at Deason Garner & Hansen Law Firm can help. Here, we
          answer the most pressing probate questions and we can provide more
          detailed information on probates in Yuma, Arizona, at our law office.
        </p>

        <h2>Answers to Your Probate Questions</h2>
        <div className="ms-3">
          <h3>What Exactly Is Probate?</h3>
          <p className="ms-4">
            Probate is a legal process that takes place after a person passes
            away. The process involves proving that their will is valid, taking
            inventory of property, appraising property, paying debts and taxes
            and distributing the remaining assets as the will (or state law)
            directs. Probate is very time-consuming and expensive and should be
            avoided if possible.
          </p>

          <h3>But I Have a Will. Isn't That Enough?</h3>
          <p className="ms-4">
            No. As long as you have assets that are subject to probate, your
            estate will go into probate, even if you have a will. There are ways
            to avoid probate, such as setting up a living trust. Our attorneys
            are experts in estate planning and can help you set up a trust or
            take any other actions you may need to avoid probate.
          </p>

          <h3>Can I Avoid Probate?</h3>
          <p className="ms-4">
            Yes. In Arizona, not all properties are subject to probate. For
            instance, real property (such as physical property) with right of
            survivorship, insurance policies with beneficiaries, annuities with
            beneficiaries, joint bank accounts, and certain property in the name
            of a trustee are not generally subject to probate. In addition, real
            property in Arizona that is assessed at $100,000 or under and
            personal property worth $75,000 or less are not subject to probate.
            If you do have property that is subject to probate, your estate goes
            into probate, whether you have a will or not. Ideally, with proper
            planning, most people can avoid having their estate go into probate
            when they die.
          </p>
          <p className="ms-4">
            Misunderstanding probate can cost you time, money and stress. An
            attorney can help you properly navigate the process.
          </p>

          <h3>
            If I Become Incapacitated, Won't My Will Ensure That My Healthcare
            Wishes are Carried Out?
          </h3>
          <p className="ms-4">
            No. A will does not go into effect until you die. So, if you are
            incapacitated, your will is not in effect. In order to ensure that
            your healthcare wishes are carried out, you will need to execute a
            healthcare power of attorney, mental healthcare power of attorney
            and living will.
          </p>

          <h3>
            {" "}
            I Have a Power of Attorney. Won't That Carry Over When I Die?
          </h3>
          <p className="ms-4">
            No. Powers of attorney are terminated when you die. So if you have
            someone designated to handle your financial affairs while you are
            living, this person cannot take care of your finances upon your
            death. For this reason, it is important to have a good estate plan
            in place to ensure that your assets are handled properly.
          </p>

          <h3>Why Should I Hire a Probate Attorney?</h3>
          <p className="ms-4">
            The probate process is expensive and time-consuming. Additionally,
            if your estate goes into probate, your wishes regarding who inherits
            what may not get carried out. There are several ways to handle your
            assets for the benefit of you and your family without your estate
            having to go through probate upon your death. Our attorneys can help
            you to navigate the complicated world of estate planning to ensure
            that you and your loved ones are taken care of in the manner that
            you desire.
          </p>
        </div>

        <h2>Schedule Your Consultation Today</h2>
        <p>
          To learn more about probate and how Shawn D. Garner and Adam D. Hansen
          can use their expertise to help you avoid probate, contact our office
          today at (928) 783-4575 for an appointment.
        </p>
      </div>
    </Page>
  );
}

export default ProbateFAQ;
