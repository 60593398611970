import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function Probate() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="probate"
      className="generic-page"
      title="Probate"
      description="Learn more about probate and how we can help you through this process"
    >
      <div className="page-content">
        <h1>Probate</h1>
        <p className="subheader">
          A probate attorney can ensure your wishes are carried out
        </p>
        <p>
          Many of our clients have misconceptions about the probate process. We
          can provide clarifications and often tell clients:
        </p>
        <ul>
          <li className="fw-normal">
            Having a will does not automatically ensure that your wishes will be
            fulfilled. A will goes into probate.
          </li>
          <li className="fw-normal">
            A power of attorney dies when you die. A will does not go into
            effect until you die.
          </li>
          <li className="fw-normal">
            A will cannot be used before death. For example, if you become
            incapacitated, your will cannot be used as a document to state your
            medical and financial wishes.
          </li>
        </ul>
        <p>
          Click <Link to="/estate-planning/probate-faq">here</Link> to find
          answers to frequently asked questions about probate
        </p>

        <h2>Why Should I Hire a Probate Lawyer?</h2>
        <p>
          Probate is a long, expensive court process. Any time the court becomes
          involved, the potential exists for your wishes not to be followed.
          Having a will only ensures that your estate will go through probate.
          There are ways to avoid probate which the attorneys at Deason Garner &
          Hansen Law Firm can explain to you. One option is to create a living
          trust, wherein you transfer title of your assets to the trust, before
          you become incapacitated or die. While you are still competent and can
          handle your affairs, you can manage the assets of the trust. Assets in
          a living trust do not go into probate upon your death. For small
          estates (real property valued at or under $100,000 and personal
          property valued at or under $75,000), beneficiaries can use a small
          estate affidavit to claim their inheritance.
        </p>

        <h2>Arizona Probate Laws</h2>
        <p>
          Probate is a legal process that takes place after someone passes away.
          The process involves proving that the will is valid, taking inventory
          of property, appraising property, paying debts and taxes, and
          distributing the remaining assets as the will (or state law) directs.
          We offer answers to common questions about probate. You can also
          receive more detailed information during a consultation with a probate
          attorney.
        </p>

        <h2>Is Probate Always Necessary?</h2>
        <p>
          In Arizona, not all properties are subject to probate. For instance,
          real property with right of survivorship, insurance policies with
          beneficiaries, annuities with beneficiaries, joint bank accounts and
          certain property in the name of a trustee are not generally subject to
          probate. Additionally, real property in Arizona that is assessed at
          $100,000 or under and personal property worth $75,000 or less are not
          subject to probate. If you do have property that is subject to
          probate, your estate goes into probate, whether you have a will or
          not. With proper planning, most people can avoid having their estate
          go into probate when they die.
        </p>

        <h2>Probate Administration</h2>
        <p>
          Probate is a court proceeding that takes place upon a person's death.
          The court appoints a personal representative to handle the affairs of
          the estate. The personal representative has many mandatory duties and
          responsibilities such as: notifying heirs and creditors about the
          estate; finding, collecting and taking inventory of the deceased's
          assets; paying the last expenses of the deceased; filing the last tax
          returns; and distributing the remaining assets to heirs.
        </p>

        <h2>Probate Petitions</h2>
        <p>
          Usually, a person's will designates a personal representative. If
          there is no personal representative designated, the court will appoint
          a personal representative. The personal representative will then start
          probate by filing a petition for probate with the court. This petition
          is a legal proceeding asking the court to review the will to ensure
          that it is valid.
        </p>
        <p>
          Probate is a long, expensive court process. Any time the court becomes
          involved, the potential exists for your wishes not to be followed.
        </p>

        <h2>Probate Disputes</h2>
        <p>
          Upon your death, your will is submitted to the court for probate,
          which is a long, complicated court process. If a family member
          contests the validity of the will, there may be further complications
          during the process. The attorneys at Deason Garner & Hansen Law Firm
          do not represent clients for will disputes, but they will guide you
          through the process of finding counsel to represent you.
        </p>

        <h2>Learn More</h2>
        <p>
          With proper estate planning, you may be able to avoid your will going
          into probate and save your family unnecessary stress and expense.
          Contact us today at (928) 783-4575 to schedule a consultation with
          Shawn D. Garner or Adam D. Hansen and learn more about your options
          for probate in Yuma, Arizona.
        </p>
      </div>
    </Page>
  );
}

export default Probate;
