import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function TrustAdministration() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="livingTrust"
      className="generic-page"
      title="Trust Administration"
      description="Learn more about trust administration and how we can help you through this process"
    >
      <div className="page-content">
        <h1>Trust Administration</h1>
        <p className="subheader">
          How a trust administration attorney can help you make an informed
          decision
        </p>
        <p>
          Certain conditions must be met for beneficiaries to receive the
          distribution of assets following the death of one or both settlors of
          a revocable living trust. Trust administration is the essential
          process to ensure all requirements are met for the trust to become
          effective. For decades, trust administration attorneys, Shawn D.
          Garner and Adam D. Hansen, and the team at Deason Garner & Hansen Law
          Firm have helped heirs receive their inheritances in a timely and
          efficient manner. We understand the sensitivity, delicacy and
          importance of these matters and treat our clients with the utmost
          respect while offering sensible legal counsel. We work to help our
          clients make the most informed decisions possible.
        </p>

        <h2>What Is a Trust Administration?</h2>
        <p>
          When an individual drafts a trust document, they are putting their
          assets in the hands of another person to manage them for the sake of
          beneficiaries in a contractual relationship. This process typically
          takes between two and six months and is best handled with legal
          counsel, not necessarily because it is difficult to do, but because
          knowing what to do is not always obvious. Our team protects trustees
          from liability by guiding them through the process. We know what
          notices they must send to beneficiaries, what consents they must
          receive from them and more. Laws governing trust administrations often
          change, and we have the ability to react in kind.
        </p>

        <h2>
          What Is the Difference Between a Trust Administration and a Probate?
        </h2>
        <p>
          A probate is a court-supervised process that determines the transfer
          of property from a decedent (an individual who has died) to the
          beneficiaries identified in their last will; or, if they died without
          a will, to their heirs. Probates and trust administrations differ in a
          few ways, including:
        </p>
        <ul>
          <li>
            Cost{" "}
            <span>
              - When someone who owns money, stocks or property dies, their
              family must present their will to the court, which will then
              appoint a representative to handle administration of the estate in
              a process called a probate. Attorney fees are based on the gross
              value of assets. Further, court filing fees, publication of notice
              of death, and other procedures are costs that must be considered.
            </span>
          </li>
          <li>
            Time{" "}
            <span>
              - An average probate will likely take a year or more, whereas a
              trust administration typically requires only two to six months
            </span>
          </li>
          <li>
            Privacy{" "}
            <span>
              - Anyone can read a court file. A court file lists assets and
              their values, and contains names and addresses of relatives and
              beneficiaries. A trust administration is a much more private
              process.
            </span>
          </li>
        </ul>
        <p>
          We strive to provide straightforward and clear legal advice to our
          clients so they can make the most informed decisions possible.
        </p>

        <h2>Duties of a Trustee</h2>
        <p>A trustee has a number of important duties, including:</p>
        <ul>
          <li>
            Follow terms of the trust{" "}
            <span>
              - The trustee must fully comprehend and follow requests outlined
              in the document. This can include, for example, making regular
              payments to beneficiaries for medical and living expenses.
            </span>
          </li>
          <li>
            Act in the best interest of the trust{" "}
            <span>
              - It is not the interests of the trustee that matters; rather, it
              is the interests of the trust that takes precedence. The trustee
              must avoid conflicts of interest, self-loans and any other
              behavior that could jeopardize the trust.
            </span>
          </li>
          <li>
            Act as a safeguard of assets{" "}
            <span>
              - The trustee will invest the trust’s assets to protect its value.
            </span>
          </li>
          <li>
            Pay taxes and expenses{" "}
            <span>
              - Trust income, bond expenses and any other expense is the
              responsibility of the trustee.
            </span>
          </li>
        </ul>

        <h2>The Deason Garner & Hansen Law Firm Advantage</h2>
        <p>
          Finding honest, committed and trustworthy legal representation often
          feels like an insurmountable task. At Deason Garner & Hansen Law Firm,
          we provide all of that and more. When you visit our office, we will
          sit down with you and discuss your specific needs and tailor a plan to
          achieve your goals. We strive to provide straightforward and clear
          legal advice to our clients so they can make the most informed
          decisions possible. We always provide an honest evaluation, and we
          have decades of experience in handling trust administrations in Yuma,
          Arizona. Finally, we keep current on changes to the law to ensure your
          heirs will receive their inheritances in full and in a timely fashion.
        </p>

        <h2>Contact Deason Garner & Hansen Law Firm</h2>
        <p>
          If you are interested in establishing a trust, we can help. You can
          schedule a consultation with one of our attorneys by contacting us
          online or by calling us at (928) 783-4575 and speaking with a member
          of our team.
        </p>
      </div>
    </Page>
  );
}

export default TrustAdministration;
