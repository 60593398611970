import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import portrait from "../../../assets/adh.webp";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-attorneys.scss";

function AdamHansen() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedCategory="about"
      selectedLevel2="meetOurAttorneys"
      className="adam-hansen generic-page"
      title="Adam D. Hansen"
      description="Adam D. Hansen is partner of Deason Garner & Hansen Law Firm"
    >
      <div className="page-content">
        <h1>Adam D. Hansen</h1>
        <img src={portrait} alt="Adam D. Hansen, Attorney" />
        <p>
          Mr. Hansen is a partner at Deason Garner & Hansen Law Firm. Mr. Hansen
          focuses in the areas of business planning, trusts and probate.
        </p>

        <h2>Education</h2>
        <ul>
          <li className="fw-normal">
            Mr. Hansen holds a Juris Doctorate degree from Arizona Summit Law
            School (Phoenix) and a bachelor's degree from Arizona State
            University (Tempe). Mr. Hansen's undergraduate focus was in Spanish
            linguistics, with extensive study and application in translation.
            Mr. Hansen also holds an associate degree from Pima Community
            College (Tucson).
          </li>
        </ul>

        <h2>Experience</h2>
        <ul>
          <li className="fw-normal">Began practicing law in Yuma in 2014.</li>
          <li className="fw-normal">
            Extensive experience with trust administration, probate, and
            business planning.
          </li>
          <li className="fw-normal">
            Featured presenter in the firm's monthly Estate Planning Seminars.
          </li>
        </ul>

        <h2>Professional Services</h2>
        <ul>
          <li className="fw-normal">
            Partner of Deason Garner & Hansen (Law Practice)
          </li>
          <li className="fw-normal">
            Partner of DGH Realty (Real Estate Brokerage)
          </li>
        </ul>

        <h2>Professional Licenses</h2>
        <ul>
          <li className="fw-normal">Arizona State Law License</li>
          <li className="fw-normal">Arizona Licensed Realtor</li>
        </ul>

        <h2>Community and Professional Involvement</h2>
        <ul>
          <li className="fw-normal">
            100+ seminars to the public on estate planning.
          </li>
          <li className="fw-normal">
            Frequent speaker for attorney continuing education courses on estate
            planning strategies.
          </li>
          <li className="fw-normal">
            Host of a weekly radio show - Life Death and the Law
          </li>
          <li className="fw-normal">
            Serving on the Board of Directors for Amberly's Place
          </li>
          <li className="fw-normal">Elder Law Executive Council Member</li>
        </ul>

        <h2>Personal</h2>
        <p>
          Mr. Hansen was born in Washington State but moved to southeastern
          Arizona when he was very young. Mr. Hansen lived in SPain for two
          years shortly after graduating high school, learning about the Spanish
          culture and language through service. Mr. Hansen met his wife,
          Jessica, while attending Arizona State University in Tempe. Mr. Hansen
          and his wife have six children: Dominick, Christian, Isabella (twin),
          Gabiella (twin), Anabelle and Cambelle. Mr. Hansen enjoys his family
          time. In addition to a busy law practice, Mr. Hansen is actively
          involved in his local church.
        </p>
      </div>
    </Page>
  );
}

export default AdamHansen;
