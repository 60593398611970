import React from "react";
import { Link } from "react-router-dom";

import Address from "./address";
import Hours from "./hours";
import Phone from "./phone";
import Links from "./links";
import RequestAppointment from "./requestAppointment";
import ContactNow from "./contactNow";
import Social from "./social";
import { Desktop, Mobile, NotMobile } from "./responsive";

import "../../styles/components/common/footer.scss";

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <Mobile>
          <div className="mobile">
            <Address darkmode />
            <Phone darkmode />
            <Hours darkmode />
            <Social darkmode />
            <RequestAppointment darkmode />
            <ContactNow />
            <nav>
              <div className="d-flex flex-column nav-tabs">
                <Links />
              </div>
            </nav>
          </div>
        </Mobile>
        <NotMobile>
          <div className="not-mobile">
            <div className="section-1">
              <Address darkmode />
              <Phone darkmode />
              <Social darkmode />
            </div>
            <Hours darkmode expanded />
            <Desktop>
              <div className="section-3">
                <RequestAppointment darkmode />
                <ContactNow />
              </div>
            </Desktop>
          </div>
        </NotMobile>
        <div className="legal">
          <p>Copyright © 2022 Deason Garner & Hansen Law Firm</p>
          <div className="documents">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
