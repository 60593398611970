import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function SocialSecurity() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="socialSecurity"
      className="generic-page"
      title="Social Security"
      description="Learn how to take full advantage of social security benefits as you plan for your future financial needs"
    >
      <div className="page-content">
        <h1>Social Security</h1>
        <p className="subheader">
          A Social Security benefits attorney can help secure benefits following
          the death of a loved one
        </p>
        <p>
          A social security benefits attorney from Deason Garner & Hansen Law
          Firm can help the surviving family members of a deceased client
          receive the benefits for which they are eligible. Our attorneys
          understand that the death of a loved one is a difficult time. For our
          clients who planned their estate through us, we can assist their
          surviving spouse or dependents in securing social security benefits.
          Although the process is relatively straightforward, many find it
          beneficial to have the aid of our experienced attorneys. We can help
          you avoid any pitfalls and make the process as stress-free as possible
          during this difficult time.
        </p>

        <h2>Steps to Take After the Death of a Social Security Beneficiary</h2>
        <p>
          After a loved one receiving social security benefits dies, you need to
          notify the Social Security Administration (SSA) of his or her death.
          Generally, you will provide the chosen funeral director with your
          loved one's social security number. The funeral director will then
          report the death to the SSA. It is also important to note that any
          benefits paid during the month of your loved one's death must be
          returned. Eligible family members can, however, receive death benefits
          for the month of the beneficiary's death.
        </p>

        <h2>Eligibility for Social Security Benefits</h2>
        <p>
          The surviving spouse is eligible for a one-time payment of $255. If a
          surviving spouse does not exist, a child may be eligible to receive
          this benefit. Other family members may also receive monthly benefits
          if they meet the necessary criteria. Eligible family members may
          include:
        </p>
        <ul>
          <li className="fw-normal">
            Widows or widowers over the age of 60, or over the age of 50 if
            disabled
          </li>
          <li className="fw-normal">
            Widows or widowers of any age providing care for children of the
            deceased under the age of 16 or those that are disabled
          </li>
          <li className="fw-normal">
            Unmarried children of the deceased who are 18 years of age or
            younger, or are 19 years of age and still in school
          </li>
          <li className="fw-normal">
            Unmarried children of the deceased who are 18 years of age or older
            with a disability that developed before the age of 22
          </li>
          <li className="fw-normal">
            Stepchildren, grandchildren, step-grandchildren and adopted
            grandchildren
          </li>
          <li className="fw-normal">
            Parents over the age of 62 who depended on the deceased for at least
            half their financial support
          </li>
          <li className="fw-normal">Surviving divorced spouses</li>
        </ul>

        <h2>What Our Social Security Benefits Attorneys Can Do for You</h2>
        <p>
          As part of our estate planning services, we help the loved ones of our
          clients secure the social security benefits to which they are
          entitled. We can meet with you personally to answer any questions and
          address any concerns you may have during this difficult time. Our
          attorneys fully understand the requirements for social security
          benefits and can help you determine whether or not you or another
          family member qualify.
        </p>

        <h2>Get the Estate Planning Assistance You Need</h2>
        <p>
          If your loved one has recently passed and you need help navigating
          social security benefits and other aspects of his or her estate,
          contact us today at (928) 783-4575 for assistance. We can assist you
          with in securing social security benefits in Yuma, Arizona, so you can
          focus on your family, and will be happy to help you make an
          appointment with Shawn D. Garner or Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default SocialSecurity;
