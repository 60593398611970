import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import portrait from "../../../assets/sdg.webp";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-attorneys.scss";

function ShawnGarner() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedCategory="about"
      selectedLevel2="meetOurAttorneys"
      className="shawn-garner generic-page"
      title="Shawn D. Garner"
      description="Shawn D. Garner is partner of Deason Garner & Hansen Law Firm"
    >
      <div className="page-content">
        <h1>Shawn D. Garner</h1>
        <img src={portrait} alt="Shawn D. Garner, Attorney" />
        <p>
          Mr. Shawn Garner is the managing partner of Deason Garner & Hansen Law
          Firm. Mr. Garner focuses in the area of Medicaid, Trusts and Probate.
        </p>

        <h2>Education</h2>
        <ul>
          <li className="fw-normal">
            BS - Summa Cum Laude Colorado State University, 2005
          </li>
          <li className="fw-normal">
            JD - University of Kentucky Law School, 2008
          </li>
        </ul>

        <h2>Professional Licences</h2>
        <ul>
          <li className="fw-normal">
            Law License (Arizona State and Federal Courts), 2008
          </li>
          <li className="fw-normal">
            Financial Planning (Series 65, Series 66, Series 7), 2015
          </li>
          <li className="fw-normal">Life Insurance (Arizona), 2015</li>
          <li className="fw-normal">
            Licensed Fiduciary (Arizona Supreme Court), 2016
          </li>
          <li className="fw-normal">Licensed Realtor, 2019</li>
        </ul>

        <h2>Experience</h2>
        <ul>
          <li className="fw-normal">
            Exclusive Estate Planning Attorney, 2012
          </li>
          <li className="fw-normal">Drafted over 5000 Wills and Trusts</li>
          <li className="fw-normal">
            Extensive experience in Trust Administration, Probate, and
            ALTCS/Medicaid
          </li>
        </ul>

        <h2>Professional Services</h2>
        <ul>
          <li className="fw-normal">Partner at Deason Garner & Hansen, 2013</li>
          <li className="fw-normal">
            Professional Alliance Partner with Ameriprise Financial, 2016
          </li>
          <li className="fw-normal">
            Partner at DGH Realty (Real Estate Brokerage), 2019
          </li>
        </ul>

        <h2>Community and Professional Involvement</h2>
        <ul>
          <li className="fw-normal">
            100+ seminars to the public on elder law topics
          </li>
          <li className="fw-normal">
            Frequent speaker for legal continuing education courses
          </li>
          <li className="fw-normal">
            Host of weekly radio show and podcast{" "}
            <span className="fst-italic">Life Death and the Law</span>
          </li>
          <li className="fw-normal">
            Probate and Trust Executive Council (PATEC) for Arizona State Bar
          </li>
          <ul>
            <li className="fw-normal">Member, 2019</li>
            <li className="fw-normal">
              Offices: Secretary, 2020; Chair Elect, 2023
            </li>
          </ul>
          <li className="fw-normal">
            National Academy of Elder Law Attorneys (NAELA), 2013
          </li>
          <li className="fw-normal">Board of Arizona Chapter of NAELA</li>
          <ul>
            <li className="fw-normal">Member, 2021</li>
            <li className="fw-normal">Offices: Secretary, 2022</li>
          </ul>
        </ul>

        <h2>Personal</h2>
        <p>
          Mr. Garner was raised in Sandpoint, Idaho. He is married with six
          children. He enjoys spending time on his small family farm, camping,
          boating, and off-road adventures in the desert.
        </p>
      </div>
    </Page>
  );
}

export default ShawnGarner;
