import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import garner from "../../../assets/sdg.webp";
import hansen from "../../../assets/adh.webp";
import sparks from "../../../assets/sparks.webp";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-attorneys.scss";

function MeetOurAttorneys() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="meetOurAttorneys"
      className="generic-page"
      title="Meet Our Attorneys"
      description="Our attorneys have the expertise and experience to guide you through whatever you are dealing with."
    >
      <div className="meet-our-attorneys page-content">
        <div>
          <h1>Meet Our Attorneys</h1>
          <p>
            The attorneys at Deason Garner & Hansen Law Firm offer unique
            backgrounds as well as over 40 years of combined legal experience.
            Since we practice exclusively in estate planning and elder law, our
            focus allows clients and those within the community to rely on the
            most accurate and up-to-date practices and options in these areas of
            law. We understand that planning for these types of situations can
            be sensitive, and offering caring guidance throughout every step of
            the process.
          </p>
        </div>

        <div>
          <h2>What We Do</h2>
          <p>
            Although we serve clients from across the U.S., we all live and work
            in Yuma, so we know the community well and support it in various
            ways. Our attorneys have been trusted by many families throughout
            the years and we have prepared over 10,000 unique estate plans to
            fit the needs and desires of our clients. Each client is unique and
            our approach allows for our clients' planning to reflect their
            specific circumstances and preferences.
          </p>
        </div>

        <div className="attorney">
          <div>
            <Link to="/about/meet-our-attorneys/adam-hansen">
              <h3>Adam D. Hansen - Partner</h3>
            </Link>
            <img src={hansen} alt="Adam D. Hansen - Partner" />
          </div>
          <div className="content">
            <p>
              Mr. Hansen graduated from Arizona Summit Law School in Phoenix,
              Arizona, in less than two and a half years. He has experience
              working in civil litigation, contract law and bankruptcy, which
              gives him a unique perspective of how his clients can avoid the
              turmoil and headache of court.
            </p>
            <p>
              He is a licensed realtor and is on the board for Amberly's Place.
            </p>

            <Link to="/about/meet-our-attorneys/adam-hansen">
              <span className="font-16">{">"} Read full bio</span>
            </Link>
          </div>
        </div>

        <div className="attorney">
          <div>
            <Link to="/about/meet-our-attorneys/shawn-garner">
              <h3>Shawn D. Garner - Partner</h3>
            </Link>
            <img src={garner} alt="Shawn D. Garner - Partner" />
          </div>
          <div className="content">
            <p>
              Mr. Garner graduated from University of Kentucky Law School in
              Lexington, Kentucky. He has experience working in various
              litigation areas of law and transitioned to estate planning and
              elder law in 2011.
            </p>
            <p>
              He is a partner for Wealth Care Financial and holds the following
              licenses and services:
            </p>
            <ul className="mb-3">
              <li>Licensed fiduciary</li>
              <li>Licensed under the Arizona Court of Appeals</li>
              <li>
                Probate and Trust Executive Counsel Section of the AZ State Bar
              </li>
              <li>Series 65, Series 66, Series 7</li>
              <li>Licensed realtor</li>
            </ul>

            <Link to="/about/meet-our-attorneys/shawn-garner">
              <span className="font-16">{">"} Read full bio</span>
            </Link>
          </div>
        </div>

        <div className="attorney">
          <div>
            <h3>Thomas Sparks - Associate Attorney</h3>
            <img src={sparks} alt="Thomas Sparks - Associate Attorney" />
          </div>
          <div className="content">
            <p>
              Thomas joined Deason Garner & Hansen Law Firm in January of 2024.
              He assists clients by meeting with them, determining their estate
              planning needs, and making recommendations to help alleviate
              hardship and worry. He received his Bachelor of Science degree in
              Geology from the University of Arizona and his Juris Doctorate
              from Gonzaga School of Law. During Law School he was commissioned
              as an Judge Advocate General (JAG) officer in the United State
              Marine Corps. After his tour of active duty he transitioned into
              the active duty reserve forces where he still serves today. He
              lives in Yuma with his wife and six children. When not in the
              office he enjoys staying active, playing sports, and spending time
              with his family.
            </p>
          </div>
        </div>

        <div>
          <h2>Schedule Your Consultation Today</h2>
          <p>
            Our attorneys have been trusted by thousands of families throughout
            the years. To learn more about our attorneys and the services that
            they offer, contact us today at (928) 783-4575 to schedule a
            consultation.
          </p>
        </div>
      </div>
    </Page>
  );
}

export default MeetOurAttorneys;
