import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

import ApptScheduler from "../common/apptScheduler";
import { Mobile, NotMobile } from "../common/responsive";

import { ReactComponent as CalendarIcon } from "../../assets/calendar-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";

function RequestAppointment({ darkmode }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <button
        className={`w-100 ${
          darkmode ? "btn-filled-secondary" : "btn-filled-secondary"
        }`}
        onClick={() => setShowModal(true)}
      >
        <CalendarIcon />
        Book An Appointment
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <div>
          <div
            className="d-flx align-self-end pb-5 pointer"
            onClick={() => setShowModal(false)}
          >
            <CloseIcon
              style={{ width: "25px", height: "25px", marginRight: "0px" }}
            />
          </div>
          <NotMobile>
            <div style={{ width: "600px" }}>
              <ApptScheduler />
            </div>
          </NotMobile>
          <Mobile>
            <ApptScheduler />
          </Mobile>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default RequestAppointment;
