import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function AssetProtection() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="assetProtection"
      className="generic-page"
      title="Asset Protection"
      description="If you are a business owner of any kind, our attorneys can help ensure that your assets are protected"
    >
      <div className="page-content">
        <h1>Asset Protection</h1>
        <p className="subheader">
          Understanding how an asset protection attorney can help your business
        </p>
        <p>
          Whether you are starting a start-up, running the family business or
          are considering leaving your full-time job to embark on a new career
          of entrepreneurialism, having a good business and asset protection
          plan in place is essential. If you have never considered asset
          protection or business planning in the past, here is what you should
          know.
        </p>

        <h2>Business Planning</h2>
        <p>
          How should you structure your business? Is your current structure
          ideal for your needs and circumstances, or are there better options?
          What are the tax implications of forming an LLC, partnership or
          corporation? Determining the answers to these questions is essential
          if you want to give your business the best chance at success and
          growth.
        </p>
        <p>
          A <Link to="/estate-planning/business-planning">business plan</Link>{" "}
          is something you develop after looking at a variety of options.
          Depending on your personal goals, business environment and the legal
          options available to you, your plan might be nothing like the business
          plan of your competitors.
        </p>

        <h2>Asset Protection</h2>
        <p>
          An asset protection plan is a collection of legal tools that shield
          you, and your business, from as much liability as possible. Without
          the proper asset protection plan in place, business difficulties could
          lead to serious consequences for you and your family. In the
          worst-case scenario, someone who sues your business could end up
          taking personal and family assets in order to satisfy a judgment
          against you.
        </p>
        <p>
          No two asset protection plans are identical, and each must meet the
          individual needs of the business, the business owners and business
          environment. The asset protection tool you might need could be very
          different than those used by colleagues or people you know.
        </p>
        <p>
          Regardless of your individual circumstances, your asset protection
          plan will look at the most likely risks you and your business will
          face, then develop tools that will help shield you from liability. A
          good plan will not only protect you and your property, but will also
          protect your business and give you peace of mind of knowing that,
          should something bad happen, you won't be left out in the cold.
        </p>

        <h2>
          Individualized Yuma, Arizona, Asset Protection and Business Planning
          Advice
        </h2>
        <p>
          Knowing what asset protection steps you need to take and how you need
          to plan your business is usually something that most people are
          capable of doing on their own. Deason Garner & Hansen Law Firm has
          years of experience helping our clients manage their business needs,
          develop comprehensive asset protection plans and providing them the
          ongoing support they need as their businesses, and lives, grow and
          change.
        </p>
        <p>
          If you haven't already done so, you need to contact our office at
          (928) 783-4575 immediately to schedule an appointment with our
          attorneys, Shawn D. Garner or Adam D. Hansen, and come talk to us
          about your asset protection and business planning options in Yuma,
          Arizona. We offer FREE consultations to be sure you are confident with
          the planning options available, and have a clear next step to have the
          proper business protections in place.
        </p>
      </div>
    </Page>
  );
}

export default AssetProtection;
