import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/react-popper-tooltip.css";
import { usePopperTooltip } from "react-popper-tooltip";

import { Mobile, NotMobile } from "./responsive";

import { ReactComponent as LessIcon } from "../../assets/less-icon.svg";
import { ReactComponent as MoreIcon } from "../../assets/more-icon.svg";

function Links({ selectedTab, selectedCategory, selectedLevel2 }) {
  const [expandCategory, setExpandCategory] = useState(selectedTab);

  return (
    <ul>
      <li
        className={
          "nav-tab" +
          (selectedTab === "home" && !selectedCategory ? " selected" : "")
        }
      >
        <Link to="/">
          <span className="tab-content">Home</span>
        </Link>
      </li>
      <Tab
        tabText="About"
        tabLink="about"
        selectedTab={selectedTab}
        selectedTabMatch="about"
        selectedLevel2={selectedLevel2}
        expandCategory={expandCategory}
        handleClick={() => setExpandCategory(expandCategory ? "" : "about")}
        submenuConfig={[
          {
            level2Match: "estatePlanningSeminars",
            pageLink: "/about/estate-planning-seminars",
            linkText: "Estate Planning Seminars",
          },
          {
            level2Match: "meetOurAttorneys",
            pageLink: "/about/meet-our-attorneys",
            linkText: "Meet Our Attorneys",
          },
          {
            level2Match: "meetOurTeam",
            pageLink: "/about/meet-our-team",
            linkText: "Meet Our Team",
          },
          {
            level2Match: "lifeDeathLaw",
            pageLink: "/about/life-death-and-the-law",
            linkText: "Life, Death, & the Law - Listen Now",
          },
          {
            level2Match: "reviews",
            pageLink: "/about/reviews",
            linkText: "Reviews",
          },
          {
            level2Match: "whyWorkWithUs",
            pageLink: "/about/why-work-with-us",
            linkText: "Why Work with Us?",
          },
        ]}
      />
      <Tab
        tabText="Estate Planning"
        tabLink="estate-planning"
        selectedTab={selectedTab}
        selectedTabMatch="estatePlanning"
        selectedLevel2={selectedLevel2}
        expandCategory={expandCategory}
        handleClick={() =>
          setExpandCategory(expandCategory ? "" : "estatePlanning")
        }
        submenuConfig={[
          {
            level2Match: "assetProtection",
            pageLink: "/estate-planning/asset-protection",
            linkText: "Asset Protection",
          },
          {
            level2Match: "businessPlanning",
            pageLink: "/estate-planning/business-planning",
            linkText: "Business Planning",
          },
          {
            level2Match: "estateTaxExclusions",
            pageLink: "/estate-planning/estate-tax-exclusions",
            linkText: "Estate Tax Exclusions",
          },
          {
            level2Match: "familyOwnedBusiness",
            pageLink: "/estate-planning/family-owned-business",
            linkText: "Family-Owned Business",
          },
          {
            level2Match: "fiduciaryServices",
            pageLink: "/estate-planning/fiduciary-services",
            linkText: "Fiduciary Services",
          },
          {
            level2Match: "gunTrusts",
            pageLink: "/estate-planning/gun-trusts",
            linkText: "Gun Trusts",
          },
          {
            level2Match: "livingTrust",
            pageLink: "/estate-planning/living-trust",
            linkText: "Living Trust",
          },
          {
            level2Match: "powerOfAttorney",
            pageLink: "/estate-planning/power-of-attorney",
            linkText: "Power of Attorney",
          },
          {
            level2Match: "probate",
            pageLink: "/estate-planning/probate",
            linkText: "Probate",
          },
          {
            level2Match: "retirementPlanning",
            pageLink: "/estate-planning/retirement-planning",
            linkText: "Retirement Planning",
          },
          {
            level2Match: "specialNeedsTrust",
            pageLink: "/estate-planning/special-needs-trust",
            linkText: "Special Needs Trust",
          },
          {
            level2Match: "wills",
            pageLink: "/estate-planning/wills",
            linkText: "Wills",
          },
        ]}
      />
      <Tab
        tabText="Elder Law"
        tabLink="elder-law"
        selectedTab={selectedTab}
        selectedTabMatch="elderLaw"
        selectedLevel2={selectedLevel2}
        expandCategory={expandCategory}
        handleClick={() => setExpandCategory(expandCategory ? "" : "elderLaw")}
        submenuConfig={[
          {
            level2Match: "guardianshipAndConservatorship",
            pageLink: "/elder-law/guardianship-and-conservatorship",
            linkText: "Guardianship & Conservatorship",
          },
          {
            level2Match: "healthcareDecisions",
            pageLink: "/elder-law/healthcare-decisions",
            linkText: "Healthcare Decisions",
          },
          {
            level2Match: "longTermCare",
            pageLink: "/elder-law/long-term-care",
            linkText: "Long-Term Care",
          },
          {
            level2Match: "medicaidPlanning",
            pageLink: "/elder-law/medicaid-planning",
            linkText: "Medicaid Planning",
          },
          {
            level2Match: "socialSecurity",
            pageLink: "/elder-law/social-security",
            linkText: "Social Security",
          },
          {
            level2Match: "veteransBenefits",
            pageLink: "/elder-law/veterans-benefits",
            linkText: "Veterans' Benefits",
          },
        ]}
      />
      <li
        className="nav-tab"
        onClick={() =>
          window.open(
            "https://app.clientpay.com/home/payment/#/paymentPage/0c27c2d1-d7f5-49a2-b5f0-bfcc8045dc33"
          )
        }
      >
        <span className="tab-content">Make Payment</span>
      </li>
      <li
        className={
          "nav-tab" +
          (selectedTab === "contactUs" && !selectedCategory ? " selected" : "")
        }
      >
        <Link to="/contact-us">
          <span className="tab-content">Contact Us</span>
        </Link>
      </li>
    </ul>
  );
}

function Tab({
  tabText,
  tabLink,
  selectedTab,
  selectedTabMatch,
  selectedLevel2,
  expandCategory,
  handleClick,
  submenuConfig,
}) {
  const [visible, setVisible] = useState(false);
  const { setTriggerRef } = usePopperTooltip({
    offset: [0, 0],
    trigger: "hover",
    onVisibleChange: () => setVisible(!visible),
  });

  return (
    <React.Fragment>
      <Mobile>
        <li
          className={
            "nav-tab" +
            (selectedTab === selectedTabMatch && !selectedLevel2
              ? " selected"
              : "")
          }
          onClick={handleClick}
        >
          <span className="tab-content">
            {expandCategory !== selectedTabMatch && <MoreIcon />}
            {expandCategory === selectedTabMatch && <LessIcon />}
            {tabText}
          </span>
        </li>
        {expandCategory === selectedTabMatch && (
          <Submenu
            selectedLevel2={selectedLevel2}
            submenuConfig={submenuConfig}
          />
        )}
      </Mobile>
      <NotMobile>
        <li
          className={
            "nav-tab" + (selectedTab === selectedTabMatch ? " selected" : "")
          }
          ref={setTriggerRef}
        >
          <Link to={`/${tabLink}`}>
            <span className="tab-content">{tabText}</span>
          </Link>
          <Submenu
            selectedLevel2={selectedLevel2}
            submenuConfig={submenuConfig}
          />
        </li>
      </NotMobile>
    </React.Fragment>
  );
}

function Submenu({ selectedLevel2, submenuConfig }) {
  return (
    <div className="nav-tabs">
      <ul className="submenu">
        {submenuConfig.map((t, i) => (
          <SubmenuLink key={i} selectedLevel2={selectedLevel2} config={t} />
        ))}
      </ul>
    </div>
  );
}

function SubmenuLink({ selectedLevel2, config }) {
  return (
    <li
      className={
        "nav-tab level-2" +
        (selectedLevel2 === config.level2Match ? " selected" : "")
      }
    >
      <Link to={config.pageLink}>
        <span className="tab-content">{config.linkText}</span>
      </Link>
    </li>
  );
}

export default Links;
