import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function MedicaidPlanning() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="medicaidPlanning"
      className="generic-page"
      title="Medicaid Planning"
      description="Learn how to take full advantage of Medicaid as you plan for your future financial needs"
    >
      <div className="page-content">
        <h1>Medicaid Planning</h1>
        <p className="subheader">
          Get help planning for your future with a Medicaid/Medicare planning
          attorney
        </p>
        <p>
          A Medicaid/Medicare planning attorney at Deason Garner & Hansen Law
          Firm can help clients become eligible for Medicaid and receive
          financial support for long-term care. Our attorneys have more than 20
          years of experience in estate planning. We have a thorough
          understanding of Medicaid and Medicare and the various laws
          surrounding these plans. Healthcare for seniors or disabled persons is
          not only expensive but can exceed a person's financial means. Creating
          an effective plan with the help of our expert attorneys can ensure you
          avoid penalties and other factors that may disqualify you from the
          care you need.
        </p>
        <p>
          A estate planning attorney can help you ensure that your family has
          the provisions they need while also allowing you qualify for Medicare
          or Medicaid.
        </p>

        <h2>Difference Between Medicare and Medicaid</h2>
        <p>
          Medicare provides healthcare for persons over the age of 65. It is a
          federal program intended for more short-term use. Medicaid, however,
          provides healthcare for those with low incomes, regardless of age.
          Both the federal and state governments fund this program. It is
          possible to qualify for both programs (known as dual eligibility).
        </p>
        <p className="fst-italic">
          Healthcare for seniors or disabled persons is not only expensive but
          can exceed a person's financial means.
        </p>

        <h2>Laws Affecting Eligibility</h2>
        <p>
          Laws surrounding these programs have changed in recent years. There is
          now a "look-back" period of five years (instead of three). This means
          the government can look back up to five years to evaluate your income.
          Gifts, assets and other factors within these five years can
          significantly affect your eligibility for these programs. Fortunately,
          our attorneys can help you understand these laws and ensure you take
          the necessary steps to qualify when the time comes.
        </p>

        <h2>Importance of Planning Ahead for Healthcare</h2>
        <p>
          In a lot of cases, seniors move into a nursing home and must use their
          own savings to pay the exorbitant costs. Once their savings run out,
          they can then qualify for Medicaid to pay the costs of the nursing
          home. With proper planning, however, clients can become eligible for
          Medicaid sooner, without eliminating their life savings. Investing in
          long-term care insurance can also allow you to experience the full
          benefits of the Medicare and Medicaid programs.
        </p>

        <h2>
          Benefits of Working With Our Medicaid/Medicare Planning Attorneys
        </h2>
        <p>
          Our attorneys have a thorough understanding of the laws surrounding
          these programs. We can analyze your financial situation and determine
          the best way to protect your estate for the benefit of a spouse or
          children with an individualized asset protection plan. At Deason
          Garner & Hansen Law Firm, our attorneys have a variety of tools and
          strategies at our disposal to help you make the most of your assets
          without affecting your eligibility for future Medicaid/Medicare needs.
          We will work with you to develop the best plan for your individual
          situation.
        </p>

        <h2>Plan for Your Future Healthcare Needs</h2>
        <p>
          Waiting to plan for your future healthcare needs can mean spending
          your life's savings on nursing home costs. This can leave your spouse
          or family without the financial means they need. With proper planning,
          you can ensure your family has the provisions they need while also
          ensuring you qualify for Medicare or Medicaid. Contact us today at
          (928) 783-4575 for expert advice from our Shawn D. Garner or Adam D.
          Hansen and to learn more about Medicaid and Medicare planning in Yuma,
          Arizona.
        </p>
      </div>
    </Page>
  );
}

export default MedicaidPlanning;
