import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";
import SeminarScheduler from "../../common/seminarScheduler";

import "../../../styles/components/pages/estate-planning-seminars.scss";

function EstatePlanningSeminars() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="estatePlanningSeminars"
      className="generic-page estate-planning-seminars"
      title="Estate Planning Seminars"
      description="We offer free estate planning seminars to help you get started with your own planning strategy. We invite you to join us at our next event!"
    >
      <div className="page-content">
        <SeminarScheduler />
      </div>
    </Page>
  );
}

export default EstatePlanningSeminars;
