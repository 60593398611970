import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";

import Input from "../form/input";

import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as MessageIcon } from "../../assets/message-icon.svg";

function ContactNow() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
      );
      setMessageSent(true);
      setIsError(false);
      e.target.reset();
    } catch (e) {
      setIsError(true);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setMessageSent(false);
    setIsError(false);
  };

  return (
    <React.Fragment>
      <button
        className="w-100 btn-filled-secondary"
        onClick={() => {
          setShowModal(true);
          setFirstName("");
          setLastName("");
          setPhone("");
          setEmail("");
          setMessage("");
        }}
      >
        <MessageIcon />
        Contact Now
      </button>

      <Modal show={showModal} onHide={handleClose} centered>
        <div>
          <div
            className="d-flx align-self-end mb-4 pointer"
            onClick={() => setShowModal(false)}
          >
            <CloseIcon
              style={{ width: "25px", height: "25px", marginRight: "0px" }}
            />
          </div>
          {isError && (
            <p className="font-16 sura red mb-3" style={{ maxWidth: "300px" }}>
              An error occured while attempting to send your message. Please try
              again or contact us via phone if the error persists. Sorry for the
              inconvenience.
            </p>
          )}
          {!messageSent && (
            <form
              ref={form}
              onSubmit={sendEmail}
              className="d-flex align-items-start"
            >
              <h1 className="mb-4">How can we help you?</h1>
              <Input
                name="firstName"
                value={firstName}
                label="First Name"
                onChange={setFirstName}
                required={true}
              />
              <Input
                name="lastName"
                value={lastName}
                label="Last Name"
                onChange={setLastName}
                required={true}
              />
              <Input
                name="phone"
                type="tel"
                value={phone}
                label="Phone Number"
                onChange={setPhone}
                required={true}
              />
              <Input
                name="email"
                type="email"
                value={email}
                label="Email"
                onChange={setEmail}
                required={true}
              />
              <p className="font-18 mb-3">Reason for contacting us:</p>
              <textarea
                name="message"
                className="form-control"
                rows="6"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <button
                type="submit"
                value="Send"
                className="btn-filled-tertiary w-100 mt-4"
              >
                Submit
              </button>
            </form>
          )}
          {messageSent && (
            <div style={{ maxWidth: "300px" }}>
              <p className="font-18 sura mb-3">Thank you!</p>
              <p className="font-18 sura">
                Your message has been sent. We will reach out to you as soon as
                we can.
              </p>
            </div>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default ContactNow;
