import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function LongTermCare() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="longTermCare"
      className="generic-page"
      title="Long-Term Care"
      description="Learn more about how our attorneys can help you prepare to handle the likelihood of long-term care"
    >
      <div className="page-content">
        <h1>Long-Term Care</h1>
        <p className="subheader">
          A long-term care attorney can help you plan for your future
        </p>
        <p>
          Good planning can help ensure that you have the knowledge and
          resources to handle the likelihood of long-term care. A long-term care
          attorney at Deason Garner & Hansen Law Firm can offer the expertise
          you need. Our team can also help create a personalized plan and offer
          guidance so your wishes will be carried out as you intended, should
          the situation arise.
        </p>

        <h2>What Constitutes Long-term Care?</h2>
        <p>
          Did you know that 70% of Americans age 65 and older will be
          incapacitated for a period of up to 25 months before they die? For
          this reason, it is important that you have planned in advance for
          whatever needs arise. Keep in mind that you may need short-term care
          for temporary incapacity from surgery or an injury. You may also need
          long-term care due to an accident or a chronic health issue, such as
          heart disease. Whatever type of care you require, it is important for
          you to have the proper planning in place to care for your unique
          needs.
        </p>
        <p>
          Long-term care refers to services and supports that you may require to
          satisfy your personal care needs over many months or years. These
          needs, which are commonly referred to as activities of daily living
          (ADLs), may include:
        </p>
        <ul>
          <li className="fw-normal">Bathing</li>
          <li className="fw-normal">Dressing</li>
          <li className="fw-normal">Using the bathroom</li>
          <li className="fw-normal">Eating</li>
          <li className="fw-normal">Transferring to a chair or bed</li>
          <li className="fw-normal">Caring for incontinence</li>
        </ul>
        <p>
          Other considerations can include assistance with housework, paying
          bills, taking medications, meal preparation and shopping. Long-term
          care providers can involve unpaid family members or friends, a nurse
          who comes to the home, transportation services, assisted-living
          centers and nursing homes, among others.
        </p>
        <p>
          Our attorneys can work with you and your family to explore ways to
          finance any potential long-term care needs.
        </p>

        <h2>Affording Long-term Care</h2>
        <p>
          You may need help at home with several ADLs, or you may need the care
          provided at a nursing home. Whatever needs you may end up having, you
          should plan in advance for the cost associated with that care. It can
          cost anywhere from $4,000-6,000 per month to live in a nursing home.
          An assisted living facility can cost up to $3,500 per month. Adult day
          care can cost up to $1,500 per month.
        </p>
        <p>
          Medicare only pays for medically necessary care and its focus is on
          acute care, rather than long-term care for a disability. Medicare will
          also cover short-term services for conditions that are expected to
          improve, such as physical or occupational therapy after an accident.
          Under certain conditions, Medicare will help pay for a short stay in a
          skilled nursing facility, for home healthcare or for hospice care.
        </p>
        <p>
          Like Medicare, health and disability insurance are not intended to
          cover long-term care. Our attorneys can work with you and your family
          to explore ways to finance any potential long-term care needs. One
          option to consider is long-term care insurance. As your health
          declines with age, you may not qualify for long-term care insurance,
          so it is important to start planning for long-term care when you are
          younger and healthier. Depending on your policy, it is possible to use
          life insurance to pay for long-term care needs. Other options for
          paying for long-term care include annuities, reverse mortgages, and
          trusts. Our attorneys are experts at navigating long-term care options
          and determining the best way to finance these types of care.
        </p>

        <h2>What Is ALTCS?</h2>
        <p>
          ALTCS is the Arizona Long Term Care System. It is part of the Arizona
          Medicaid Program and is designed for people who are 65 and older,
          physically or mentally disabled, or both, and need to enter a skilled
          nursing facility or assisted living facility. Many people can also
          receive services in their home. In order to receive benefits under
          ALTCS, there are general, medical, and financial requirements that
          need to be met. The attorneys can guide you through this complex
          process.
        </p>

        <h2>Learn More During a Consultation</h2>
        <p>
          Most people will need long-term care at some point during their
          lifetime. Shawn D. Garner and Adam D. Hansen have the expertise to
          guide you in determining how best to plan for long-term care in Yuma,
          Arizona. Schedule your consultation by contacting our office today at
          (928) 783-4575.
        </p>
      </div>
    </Page>
  );
}

export default LongTermCare;
