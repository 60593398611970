import React from "react";

import Address from "./common/address";
import ContactNow from "./common/contactNow";
import Phone from "./common/phone";
import Social from "./common/social";
import RequestAppointment from "./common/requestAppointment";
import { Mobile, NotMobile, Desktop, Tablet } from "./common/responsive";

function Banner() {
  return (
    <div className="background-black">
      <NotMobile>
        <div className="container d-flex justify-content-between p-3 pb-4">
          <div className="d-flex">
            <div className="pe-5">
              <Address darkmode />
            </div>
            <Phone darkmode />
          </div>
          <Desktop>
            <div className="d-flex">
              <div style={{ width: "250px" }}>
                <RequestAppointment darkmode />
              </div>
              <div className="ms-3" style={{ width: "250px" }}>
                <ContactNow darkmode />
              </div>
            </div>
          </Desktop>
          <Tablet>
            <div>
              <div className="mb-3">
                <RequestAppointment darkmode />
              </div>
              <ContactNow darkmode />
            </div>
          </Tablet>
        </div>
      </NotMobile>
      <Mobile>
        <div className="d-flex justify-content-between p-3 pb-4">
          <Address darkmode />
          <Social darkmode />
        </div>
      </Mobile>
    </div>
  );
}

export default Banner;
