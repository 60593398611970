import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function FiduciaryServices() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="fiduciaryServices"
      className="generic-page"
      title="Fiduciary Services"
      description="Learn more about how fiduciary services can benefit you if you were to become incapacitated or deceased"
    >
      <div className="page-content">
        <h1>Fiduciary Services</h1>
        <p>
          Deason Garner & Hansen Law Firm offers professional fiduciary services
          in Yuma, Arizona, which can help get things done when you are either
          incapacitated or deceased.
        </p>
        <p>
          Many individuals that we meet with often ask us to take the driver's
          seat in their plan. Either they are hesitant to name individuals that
          they know to be a fiduciary under their plan or maybe they trust us to
          make the right decisions. After all, we have a history of experience
          and we helped our clients develop and implement their plan.
        </p>
        <p>
          What better way to feel comforted knowing that your plan will be
          managed according to your wishes than by naming Deason Garner & Hansen
          Law Firm as your plan's fiduciary? Fiduciary service protections are
          an additional service that our attorneys offers to our clients for a
          reasonable fee, deducted from the estate at the time of
          administration.
        </p>
        <p>
          Contact us at (928) 783-4575 to learn more about fiduciary services
          and schedule an appointment with Shawn D. Garner and Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default FiduciaryServices;
