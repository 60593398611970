import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function EstateTaxExclusions() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="estateTaxExclusions"
      className="generic-page"
      title="Estate Tax Exclusions"
      description="Learn more about the estate tax exclusion and how it affects your estate plan"
    >
      <div className="page-content">
        <h1>Estate Tax Exclusions</h1>
        <p className="subheader">
          The estate tax exclusion and how it affects your estate plan
        </p>
        <p>
          One of Congress' enumerated powers under our Constitution is the power
          to lay and collect taxes (Article I, Section 8). Congress has used
          this power to implement what is commonly referred to as an “estate
          tax.” What that means is that if you have an estate value that is over
          a certain limit, than the amount over that certain limit will be
          subject to high taxation.
        </p>

        <h2>Where We are Now and How We Got Here</h2>
        <p>
          In 2001, Congress passed a law that raised the estate value amount
          that could pass without taxation, increasing it in steps from $675,000
          in 2001, to $3.5 million in 2009. Then, in 2010, Congress passed a law
          that would repeal the estate tax for one year only, with the provision
          that it would return in 2011 with estates over $1 million being taxed
          as high as 55%. However, on December 17, 2010, Congress revised the
          estate tax with yet another new law: the Tax Relief, Unemployment
          Insurance Reauthorization, and Job Creation Act of 2010 (“TRA 2010”).
          This new law set the estate value amount that could pass without
          taxation at $5 million, per person, from 2010-2012. TRA 2010 was
          designed to be a temporary law and was set to expire on December 31,
          2012. On January 1, 2013, Congress made TRA 2010's $5 million
          exemption permanent, with built-in adjustments for inflation. Today
          (as of 2015) if an estate is worth less than $5.43 million, then that
          estate will not be subject to a federal estate tax. For estates that
          exceed the $5.43 million limit, any excess amount over $5.43 million
          will be subject to a tax of 40%. For example, if John Doe has an
          estate valued at $5.1 million at the time of his death, than John
          Doe's executor will not have to pay the IRS estate taxes. However, if
          John Doe had an estate valued at $5.6 million at the time of his
          death, John Doe's executor would have to write a check to the IRS,
          from John Doe's estate, for estate taxes to the tune of $68,000.
        </p>

        <h2>Portability</h2>
        <p>
          With TRA 2010, Congress also introduced a new “portability” provision.
          Portability means that one spouse can add their deceased spouse's
          estate tax exclusion to their own exclusion, to shelter more from
          taxes. This portability provision, also known as the “Deceased Spousal
          Unused Exclusion Amount” can be used to shelter the assets of the
          surviving spouse and is a very effective tool when used correctly in a
          comprehensive estate plan.
        </p>

        <h2>Summary</h2>
        <p>
          While planning to minimize or avoid estate taxes is certainly an
          important reason to meet with an estate planning attorney, creating an
          estate plan is about much more than protecting your beneficiaries'
          inheritance from estate taxes. Planning for your estate and your
          legacy can protect your beneficiaries and the assets you leave to them
          from their creditors, a future divorce and even their own misjudgment.
          Estate planning is also about providing protections during lifetime,
          such as avoiding a guardianship or conservatorship if you're
          incapacitated and protecting your nest egg from the possibility of an
          extended stay in a nursing home.
        </p>
        <p>
          Contact Deason Garner & Hansen Law Firm at (928) 783-4575 to learn
          more about how the portability provision could affect your estate plan
          in Yuma, Arizona, and make an appointment with our attorneys, Shawn D.
          Garner and Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default EstateTaxExclusions;
