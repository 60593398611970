import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import savannah from "../../../assets/savannah.webp";
import jordana from "../../../assets/jordana.webp";
import jazlyn from "../../../assets/jazlyn.webp";
import alyza from "../../../assets/alyza.webp";
import kylie from "../../../assets/kylie.webp";
import lana from "../../../assets/lana.webp";
import rosa from "../../../assets/rosa.webp";
import melissa from "../../../assets/melissa.webp";
import viridiana from "../../../assets/viridiana.webp";
import zenia from "../../../assets/zenia.webp";
import marcos from "../../../assets/marcos.webp";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-team.scss";

function MeetOurTeam() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="meetOurTeam"
      className="generic-page"
      title="Meet Our Team"
      description="We are dedicated to excellence in all that we do. Learn more about our amazing team members."
    >
      <div className="page-content meet-our-team">
        <h1>Meet Our Team</h1>

        <div className="team-member">
          <img src={savannah} alt="Savannah Soto, Office Manager" />
          <div>
            <h2>Savannah Soto</h2>
            <p className="title">Office Manager</p>
            <p>
              Savannah joined the Deason Garner & Hansen Law Firm in March of
              2019. With over fourteen years of experience in the legal field,
              she has a wealth of knowledge to share with our clients. Savannah
              has a bachelor's degree in business administration with an
              emphasis in organizational management. She is also the designated
              real estate broker for our sister company, DGH Realty. When she is
              not in the office she enjoys traveling, helping her clients buy
              and sell homes, going out to the shooting range with her husband,
              and fishing.
            </p>
            <a href="mailto:Savannah@DeasonGarnerLaw.com">
              Savannah@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={jordana} alt="Jordana Canela, Estate Planning Paralegal" />
          <div>
            <h2>Jordana Canela</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Jordana assists our clients with the preparation and execution of
              their estate planning, as well as updates to their business
              planning.
            </p>
            <p>
              She was born and raised here in Yuma, Arizona. When she is not at
              work, she enjoys spending time with her family.
            </p>
            <a href="mailto:Jordana@DeasonGarnerLaw.com">
              Jordana@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img
            src={jazlyn}
            alt="Jazlyn Castro, Trust Administration & Probate Paralegal"
          />
          <div>
            <h2>Jazlyn Castro</h2>
            <p className="title">Trust Administration & Probate Paralegal</p>
            <p>
              Jazlyn joined the Deason Garner & Hansen Law Firm in June 2020,
              her main responsibilities include conducting client assessments
              and preparing various legal documents for Trust Administration and
              Probate Matters. Jazlyn strives to provide our clients with great
              support and care. Jazlyn is also a Real Estate Agent for DGH
              Realty.
            </p>
            <p>
              In her spare time, Jazlyn loves to spend time outdoors with her
              husband and children.
            </p>
            <a href="mailto:Jazlyn@DeasonGarnerLaw.com">
              Jazlyn@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={alyza} alt="Alyza Ruiz, Estate Planning Paralegal" />
          <div>
            <h2>Aylza Ruiz</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Aylza joined the Deason Garner & Hansen Law Firm in April 2021.
              She assists our clients with the preparation and execution of
              their legal documents.
            </p>
            <p>
              She was born and raised here in Yuma, Arizona. In her spare time,
              she enjoys spending time with family, friends and her dog, Kylo.
            </p>
            <a href="mailto:Aylza@DeasonGarnerLaw.com">
              Aylza@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={kylie} alt="Kylie McCloud, Estate Planning Paralegal" />
          <div>
            <h2>Kylie McCloud</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Kylie is one of the new additions to Deason Garner & Hansen Law
              Firm. She works to assist clients with the preparation and
              execution of their legal documents as well as assists in outreach
              for the marketing department of the firm. She also works as a Real
              Estate Agent for our sister company, DGH Realty.
            </p>
            <p>
              She was born and raised here in Yuma, Arizona. In her downtime,
              Kylie enjoys most traveling, spending evenings with family, and
              staying active, especially by bowling or doing anything outdoors.
            </p>
            <a href="mailto:Kylie@DeasonGarnerLaw.com">
              Kylie@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img
            src={lana}
            alt="Lana Leon-Guerrero, Estate Planning and Trust Administration & Probate Paralegal"
          />
          <div>
            <h2>Lana Leon-Guerrero</h2>
            <p className="title">
              Estate Planning and Trust Administration & Probate Paralegal
            </p>
            <p>
              Lana joined the Deason Garner & Hansen Law Firm in March 2021. She
              assists the attorneys with the preparation and execution of the
              estate planning documents as well as applications for Arizona
              Long-Term Care services, and trust administration and probate
              matters. Additionally, Lana is a Real Estate Agent for DGH Realty.
              She comes from a versatile professional background and has a
              Master of Arts degree in Education. Lana's favorite pastimes
              include hiking, camping, reading and adventuring with her husband
              and two dogs.
            </p>
            <a href="mailto:Lana@DeasonGarnerLaw.com">
              Lana@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img
            src={rosa}
            alt="Rosa Corona, Probate and Trust Administration Paralegal"
          />
          <div>
            <h2>Rosa Corona</h2>
            <p className="title">Probate and Trust Administration Paralegal</p>
            <p>
              Rosa Joined the Deason Garner & Hansen Law Firm in April 2024. She
              assists our clients with Trust Administration and Probate matters.
            </p>
            <p>
              She was born here in Yuma, Arizona and raised in Somerton. Some of
              her pastimes include, reading, road trips, Disneyland, and camping
              with her husband & three fur babies.
            </p>
            <a href="mailto:Rosa@DeasonGarnerLaw.com">
              Rosa@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img
            src={melissa}
            alt="Melissa Yee, Probate and Trust Administration Paralegal"
          />
          <div>
            <h2>Melissa Yee</h2>
            <p className="title">Probate and Trust Administration Paralegal</p>
            <p>
              Melissa Joined the Deason Garner & Hansen Law Firm in June 2024.
              She assists our clients with Trust Administration and Probate
              matters.
            </p>
            <p>
              She was born in Mexico and raised in Somerton, AZ. Some of her
              pastimes include reading, foreign films, going to the beach and
              camping with her husband & two children.
            </p>
            <a href="mailto:Melissa@DeasonGarnerLaw.com">
              Melissa@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={viridiana} alt="Viridiana Altamirano, Receptionist" />
          <div>
            <h2>Viridiana Altamirano</h2>
            <p className="title">Receptionist</p>
            <p>Viri joined Deason Garner & Hansen Law Firm in April of 2023.</p>
            <a href="mailto:Viridiana@DeasonGarnerLaw.com">
              Viridiana@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={zenia} alt="Zenia Edwards, Estate Planning Paralegal" />
          <div>
            <h2>Zenia Edwards</h2>
            <p className="title">Estate Planning Paralegal</p>
            <p>
              Zenia joined Deason Garner & Hansen Law Firm in February 2023. She
              assists our clients with funding their legal documents.
            </p>
            <p>
              In her spare time, she enjoys spending time with her family and
              friends.
            </p>
            <a href="mailto:Zenia@DeasonGarnerLaw.com">
              Zenia@DeasonGarnerLaw.com
            </a>
          </div>
        </div>

        <div className="team-member">
          <img src={marcos} alt="Marcos Angeles, Legal Aide" />
          <div>
            <h2>Marcos Angeles</h2>
            <p className="title">Legal Aide</p>
            <p>
              Marcos joined the Deason Garner & Hansen Law firm in June of 2024.
            </p>
            <p>
              In his spare time, he enjoys watching movies and spending time
              with his family & friends.
            </p>
            <a href="mailto:Marcos@DeasonGarnerLaw.com">
              Marcos@DeasonGarnerLaw.com
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default MeetOurTeam;
