import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";
import "../../../styles/components/pages/meet-our-attorneys.scss";

function LarryDeason() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedCategory="about"
      selectedLevel2="meetOurAttorneys"
      className="larry-deason generic-page"
      title="Larry Deason"
      description="Larry Deason is the founder of Deason Law Firm"
    >
      <div className="page-content">
        <h1>Larry Deason</h1>
        <p>
          Mr. Larry Deason is the founder of Deason Law Firm, and owned and
          practiced out of the firm for many years prior to his retirement. He
          established Deason Law Firm in 1991 after practicing law for 20 years
          and began practicing exclusively in the area of estate planning law.
          In 2011, he was joined by Mr. Shawn Garner, and in 2013 Mr. Garner
          became a partner and the name of the firm changed to Deason Garner &
          Hansen Law Firm.
        </p>
      </div>
    </Page>
  );
}

export default LarryDeason;
