import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import Links from "./common/links";
import { Desktop, Mobile, NotMobile } from "./common/responsive";
import Social from "./common/social";

import ContactNow from "./common/contactNow";
import RequestAppointment from "./common/requestAppointment";

import logo from "../assets/logo.webp";

import { ReactComponent as BarsIcon } from "../assets/bars-icon.svg";
import { ReactComponent as PhoneIcon } from "../assets/phone-icon.svg";
import { ReactComponent as TimesIcon } from "../assets/times-icon.svg";

import "../styles/components/nav.scss";

function Navbar({ selectedTab, selectedCategory, selectedLevel2 }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const links = (
    <div className="nav-tabs">
      <NotMobile>
        <img
          src={logo}
          width="100px"
          height="72px"
          className="me-4 align-self-center"
          alt="Deason Garner & Hansen logo"
        />
      </NotMobile>
      <Links
        selectedTab={selectedTab}
        selectedCategory={selectedCategory}
        selectedLevel2={selectedLevel2}
      />
      <Mobile>
        <div className="mx-3 mt-3">
          <RequestAppointment />
        </div>
        <div className="m-3">
          <ContactNow />
        </div>
      </Mobile>
    </div>
  );

  return (
    <React.Fragment>
      <Mobile>
        <nav>
          <div className="d-flex p-3 justify-content-between align-items-center">
            <div className="white" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded && <TimesIcon />}
              {!isExpanded && <BarsIcon />}
            </div>
            <img
              src={logo}
              width="100px"
              height="72px"
              className="align-self-center"
              alt="Deason Garner & Hansen logo"
            />

            <a href="tel: 928-783-4575">
              <PhoneIcon />
            </a>
          </div>
          <Collapse in={isExpanded}>{links}</Collapse>
        </nav>
      </Mobile>
      <NotMobile>
        <nav className="container-fluid d-flex flex-row justify-content-center">
          <div className="nav-content flex-row-aligned justify-content-between flex-grow-1">
            {links}
            <Desktop>
              <Social darkmode />
            </Desktop>
          </div>
        </nav>
      </NotMobile>
    </React.Fragment>
  );
}

export default Navbar;
