import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

function WhyWorkWithUs() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="whyWorkWithUs"
      className="generic-page"
      title="Why Work with Us?"
      description="We strive to provide the best experience for all of our clients. Learn more about the many benefits of working with our firm."
    >
      <div className="page-content">
        <h1>Why Work with Us?</h1>
        <p className="subheader">
          Are you looking for the best estate planning attorney? Learn why you
          should work with us!
        </p>
        <p>
          Home to some of the most experienced attorneys Arizona has to offer,
          Deason Garner & Hansen Law Firm is committed to meet the unique needs
          of you, your family, and your choices to ensure your planning protects
          the things that you've worked hard for and want to keep within the
          family. To learn more about the advantages or working with the best
          attorneys that Yuma, Arizona, has to offer, contact our firm today at
          (928) 783-4575 and make an appointment with Shawn D. Garner or Adam D.
          Hansen.
        </p>
        <ul>
          <li className="fw-normal">
            We offer a{" "}
            <span className="fw-bolder font-18">
              FREE one-hour personal consultation
            </span>{" "}
            for people seeking estate planning or long-term care planning
            Information.
          </li>
          <li className="fw-normal">
            Our attorneys host{" "}
            <span className="fw-bolder font-18">monthly seminars</span> to
            educate the community about strategies and unique Arizona laws to
            protect and preserve assets.
          </li>
          <li className="fw-normal">
            We have prepared over{" "}
            <span className="fw-bolder font-18">
              10,000 unique estate plans
            </span>{" "}
            over the past 40 years.
          </li>
          <li className="fw-normal">
            We know the key to estate planning is to avoid probate. We will
            guide you through the{" "}
            <span className="fw-bolder font-18">funding process</span> for your
            assets to be transferred successfully.
          </li>
          <li className="fw-normal">
            Our attorneys have helped many families{" "}
            <span className="fw-bolder font-18">
              apply and qualify for ALTCS benefits
            </span>{" "}
            to relieve the burden of the high costs of long-term care.
          </li>
          <li className="fw-normal">
            Deason Garner & Hansen Law Firm attorneys receive{" "}
            <span className="fw-bolder font-18">more than double</span> the
            amount of continuing legal education requires by State Bar of
            Arizona.
          </li>
          <li className="fw-normal">
            We offer{" "}
            <span className="fw-bolder font-18">
              special needs trust planning
            </span>{" "}
            to provide assistance to clients who have special needs family
            members.
          </li>
          <li className="fw-normal">
            Our attorneys and team love{" "}
            <span className="fw-bolder font-18">pets and all animals</span> and
            include pet planning in the estate planning process.
          </li>
          <li className="fw-normal">
            We offer{" "}
            <span className="fw-bolder font-18">
              FREE Living Trust Identification Cards
            </span>
            . These are wallet-sized laminated cards with our office address and
            phone number on one side, and the name and date of your trust on the
            other. They will serve as a handy, on-the-spot reference whenever
            you buy new investments, open a new account or conduct a transaction
            for your trust.
          </li>
          <li className="fw-normal">
            Our legal office offers{" "}
            <span className="fw-bolder font-18">
              unlimited telephone support
            </span>
            . Answers to our estate planning clients' questions are never more
            than a free telephone call away. Whether you need specific help with
            your own trust or just want information on other estate planning
            strategies, give our office a call.
          </li>
        </ul>
      </div>
    </Page>
  );
}

export default WhyWorkWithUs;
