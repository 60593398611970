import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function SpecialNeedsTrust() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="specialNeedsTrust"
      className="generic-page"
      title="Special Needs Trust"
      description="Learn more about how a special needs trust can provide for the future needs of your disabled child"
    >
      <div className="page-content">
        <h1>Special Needs Trust</h1>
        <p className="subheader">
          A special needs trust attorney can help provide security for your
          child
        </p>
        <p>
          A special needs trust attorney at Deason Garner & Hansen Law Firm can
          help you provide for the future needs of your disabled child through
          effective estate planning and creating a special needs trust (SNT). By
          creating an SNT, you help ensure that your child's needs are provided
          for, while still allowing him or her to qualify for government
          benefits. There are advantages and disadvantages to SNTs, so we
          recommend speaking with one of our attorneys to determine if this type
          of trust is right for you.
        </p>
        <p>
          A special needs trust (SNT) allows people with disabilities to receive
          financial government assistance while still having access to funds to
          cover services beyond what the government provides.
        </p>

        <h2>What Is a Special Needs Trust?</h2>
        <p>
          An SNT provides for the living needs of a disabled or special needs
          child, while also ensuring he or she remains eligible for federal and
          state benefits. SNTs can provide the child with much-needed financial
          aid, both while the parents are living and after their deaths. Without
          an SNT, the burden of care for a special needs child may fall on
          siblings or other relatives after the parents' deaths. A trust can
          provide the child with the financial means for the care he or she
          needs, without burdening other family members.
        </p>
        <p>
          By creating an SNT, you help ensure that your child's needs are
          provided for, while still allowing him or her to qualify for
          government benefits.
        </p>

        <h2>What Benefits Can It Provide?</h2>
        <p>
          The most common reason parents set up SNTs is to ensure their child is
          still eligible for government benefits such as Medicaid. Although this
          became less of a factor with the passage of the Affordable Care Act
          (ACA), our healthcare systems are facing a tumultuous time. An SNT can
          help ensure that a special needs child has access to proper healthcare
          no matter what.
        </p>

        <h2>
          Why You Should Work with a Special Needs Attorney to Set Up An SNT
        </h2>
        <p>
          To keep your child eligible for government benefits, there are many
          do's and don'ts you must follow. For instance, you cannot transfer
          your assets to your child directly, as this could disqualify him or
          her from Medicaid and other benefits. Instead, you must allocate these
          funds to a trust. A trustee can then disperse the funds according to
          your child's needs to pay for care beyond what his or her government
          benefits provide.
        </p>
        <p>
          Our attorneys are experts in estate planning, the development of
          trusts and trust administration, especially those for persons with
          special needs. We can help you create a trust that will ensure your
          child can live a comfortable life, even after you have passed on. Our
          attorneys can advise you on all the minute details needed to set up a
          trust, including:
        </p>
        <ul>
          <li className="fw-normal">Start-up costs</li>
          <li className="fw-normal">Management fees</li>
          <li className="fw-normal">
            Selecting an appropriate trustee or co-trustees
          </li>
          <li className="fw-normal">
            Defining the trustee's duties and the relationship between
            co-trustees (if applicable)
          </li>
          <li className="fw-normal">Auditing of the trustee</li>
        </ul>
        <p>
          Our attorneys can help you determine whether an SNT is right for you
          and your child. We will walk you through the entire process to ensure
          all of your and your child's needs are met.
        </p>

        <h2>Begin Planning for the Future Today</h2>
        <p>
          Our attorneys, Shawn D. Garner and Adam D. Hansen, are ready to assist
          you with all your estate planning and special need trusts in Yuma,
          Arizona. Contact our law firm today at (928) 783-4575 to make an
          appointment and start planning for the future.
        </p>
      </div>
    </Page>
  );
}

export default SpecialNeedsTrust;
