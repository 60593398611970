import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function ElderLaw() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      className="generic-page"
      title="Elder Law"
      description="Learn more about the many facets of elder law and how we can help you navigate it all"
    >
      <div className="page-content">
        <h1>Elder Law in Yuma, Arizona</h1>
        <p className="subheader">
          Protect your loved ones with the help of an elder law attorney
        </p>

        <h2>
          <Link to="/elder-law/guardianship-and-conservatorship">
            Guardianship & Conservatorship
          </Link>
        </h2>
        <p>
          If you become incapacitated and cannot make decisions regarding your
          welfare or financial affairs, the court may have to appoint a guardian
          or conservator. To avoid these situations, it is important to seek an
          attorney's assistance and plan ahead by executing the proper powers of
          attorney.
        </p>

        <h2>
          <Link to="/elder-law/healthcare-decisions">Healthcare Decisions</Link>
        </h2>
        <p>
          Our attorneys can help you to prepare the proper documentation so that
          your healthcare wishes will be carried out if you become
          incapacitated. At a minimum, you should have a Healthcare Power of
          Attorney, Mental Healthcare Power of Attorney and Living Will.
        </p>

        <h2>
          <Link to="/elder-law/long-term-care">Long-Term Care</Link>
        </h2>
        <p>
          At least 70% of Americans over 65 will need some type of long-term
          care for a period of up to 25 months before they die. A nursing home
          can cost from $4,000-$6,000 per month. It is essential to plan for
          long-term care needs, and our attorneys can assist you with their
          experience in this area.
        </p>

        <h2>
          <Link to="/elder-law/medicaid-planning">Medicaid Planning</Link>
        </h2>
        <p>
          Under certain conditions, Medicare will pay for part of 100 days of
          long-term care. Medicaid pays for long-term care, but there are
          financial qualifications to meet in addition to state requirements.
          Our attorneys may be able to help you to qualify for Medicaid to
          defray the costs of long-term care.
        </p>

        <h2>
          <Link to="/elder-law/social-security">Social Security</Link>
        </h2>
        <p>
          When you pass away, some of your surviving family may be eligible for
          Social Security benefits. Our attorneys can verify what types of
          benefits your family can receive upon your death. Knowing this
          information is an essential part of an overall estate plan.
        </p>

        <h2>
          <Link to="/elder-law/veterans-benefits">Veterans' Benefits</Link>
        </h2>
        <p>
          If you served in the military, there are several benefits available to
          you through the Department of Veterans Affairs (VA). The VA pays for
          healthcare for disabled veterans and more. Our attorneys can help you
          navigate through the governmental paperwork to get the benefits you
          deserve.
        </p>

        <p>
          To find out more about elder law in Yuma, Arizona, and make an
          appointment with Shawn D. Garner and Adam D. Hansen call Deason Garner
          & Hansen Law Firm today at (928) 783-4575.
        </p>

        <ul>
          <li>
            <Link to="/elder-law/guardianship-and-conservatorship">
              Guardianship & Conservatorship
            </Link>
          </li>

          <li>
            <Link to="/elder-law/healthcare-decisions">
              Healthcare Decisions
            </Link>
          </li>
          <li>
            <Link to="/elder-law/long-term-care">Long-Term Care</Link>
          </li>
          <li>
            <Link to="/elder-law/medicaid-planning">Medicaid Planning</Link>
          </li>
          <li>
            <Link to="/elder-law/social-security">Social Security</Link>
          </li>
          <li>
            <Link to="/elder-law/veterans-benefits">Veterans' Benefits</Link>
          </li>
        </ul>
      </div>
    </Page>
  );
}

export default ElderLaw;
