import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function RetirementPlanning() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="retirementPlanning"
      className="generic-page"
      title="Retirement Planning"
      description="Learn more about the steps of retirement planning and how our attorneys can help you meet your goals"
    >
      <div className="page-content">
        <h1>Retirement Planning</h1>
        <p className="subheader">
          Trust an experienced IRA and retirement planning attorney
        </p>
        <p>
          Your current plan may include a variety of retirement planning methods
          like an IRA, a 401(K) or other tools. The best laid plans allow for
          flexibility as your needs and circumstances change. Here are a few
          steps you can take as your plan comes together. However, since each
          person's plan and needs are unique, an IRA and retirement planning
          attorney at our firm can ensure you are choosing the smartest options
          for retirement planning in Yuma, Arizona.
        </p>

        <h2>Retirement Planning in Yuma, Arizona</h2>
        <ul>
          <li>
            Step 1 - Seek Help:{" "}
            <span>
              Utilize a professional or group of professionals who can talk with
              you about your goals and discuss your best options available.
            </span>
          </li>
          <li>
            Step 2 - Review Your Goals:{" "}
            <span>
              Evaluating your current needs are important in comparison to how
              you want your planning to be in the future. Honestly look at your
              finances a list out your assets and debts to consider how those
              finances impact your options.
            </span>
          </li>
          <li>
            Step 3 - Organize:{" "}
            <span>
              Put together all the current statements and list of assets and
              keep them in a space that is easily accessible, such as an excel
              document or folder so the statements are available when needed.
            </span>
          </li>
          <li>
            Step 4 - Look Ahead:{" "}
            <span>
              Take advantage of the time you have now and what your priorities
              are now and for those who mean the most to you.
            </span>
          </li>
          <li>
            Step 5 - Set Goals:{" "}
            <span>
              Don't delay getting the information you deserve and putting
              together your best plan.
            </span>
          </li>
        </ul>
        <p>
          Don't delay getting the information you deserve and putting together
          your best plan.
        </p>

        <h2>Begin Planning Now</h2>
        <p>
          A plan to take care of you and your needs when you retire is something
          everyone in Arizona needs. Let us help you get it started and show you
          how it can be part of your Estate Planning efforts. Our firm has years
          of experience assisting our clients develop the retirement planning
          they need. Set up your consultation with our attorneys, Shawn D.
          Garner or Adam D. Hansen and we can talk together about your concerns
          and priorities so your plan reflects and meets your needs. To get
          started, contact Deason Garner & Hansen Law Firm today at (928)
          783-4575.
        </p>
      </div>
    </Page>
  );
}

export default RetirementPlanning;
