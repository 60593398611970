import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";
import { Link } from "react-router-dom";

import Page from "../../common/page";

import video from "../../../assets/building.mp4";

import "../../../styles/components/pages/generic-page.scss";

function About() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      className="generic-page"
      title="About Us"
      description="Our attorneys specialize in estate planning and elder law. We are committed to providing high quality legal services to you and your family."
    >
      <div className="page-content">
        <h1>Attorneys in Yuma, AZ</h1>
        <p className="subheader">About Our Estate Planning Firm</p>

        <video className="w-100" muted autoPlay="autoplay" loop playsInline>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <h2>Practice History</h2>
        <p>
          In 1991, after practicing law for about 20 years,{" "}
          <Link to="/about/meet-our-attorneys/larry-deason">
            Mr. Larry Deason
          </Link>{" "}
          began practicing exclusively in the area of estate planning law. In
          2011, Mr. Shawn Garner joined the firm, having previously established
          a very successful litigation practice. In 2013, Mr. Garner became a
          partner of the law firm and the Deason Law Firm became the Deason
          Garner & Hansen Law Firm. In 2014, Mr. Adam Hansen began his role in
          the firm as a licensed attorney.
        </p>
        <p>
          During its long history, Deason Garner & Hansen Law Firm has
          established itself as the premier law firm in all matters regarding
          wills, trusts, probate, trust administration, Medicaid and estate
          planning in the Yuma community. Deason Garner & Hansen Law Firm has
          been recognized year after year as being voted Yuma's Best Law Firm.
          Deason Garner & Hansen Law Firm continues to build lifelong
          relationships that span generations.
        </p>

        <h2>What Sets Us Apart</h2>
        <p>
          Our firm is committed to offering quality and trustworthy legal
          services for the legal matters that mean the most to you. Visit or
          call our office and you will start to see the Deason Garner & Hansen
          Law Firm difference. It is important that you are comfortable and
          confident when making a decision that can last for generations. We
          will equip you with the information, options and facts needed to make
          the best choice for your family and their future.
        </p>
        <p>
          A well-drafted estate plan will make certain that the assets you have
          accumulated over your lifetime will pass to exactly the individuals
          you want and exactly how you want it to happen. Our goal is to provide
          planning that will avoid the lengthy, expensive and public matter of
          probate and ensure that the state will not be a part of the
          distributions. However, please be aware that the options for estate
          planning are endless and you should choose a firm that has your best
          interests.
        </p>
        <p>
          Our estate planning firm offers a library of free information on our
          estate planning blog, our radio show archive and if you are in town,
          please feel free to attend one of our free estate planning seminars.
          We want you to be confident and comfortable with the decisions made in
          regards to the future of your family.
        </p>

        <h2>Estate Planning</h2>
        <p>
          An estate plan is a formal plan for your asset, guardianship and
          healthcare decisions. It isn't enough to simply tell someone of your
          wishes. We craft a revocable or irrevocable living trust, last will,
          power of attorney and healthcare documents custom tailored to your
          needs. All of our estate plans include the necessary provisions to
          avoid probate and keep your family out of court. Each individual is
          different and each family has unique needs, that is why our estate
          planning services are custom and comprehensive to develop exactly the
          planning needed for you.
        </p>

        <h2>Legacy Planning</h2>
        <p>
          Legacy planning is a more comprehensive form of estate planning. When
          you craft your legacy, you leave behind a lasting impression that will
          transcend mere dollars and cents. With our assistance, our clients
          have preserved their financial wealth as well as their legacy. This
          includes personal life stories and treasured family heirlooms as well
          as homes and land. We use a vast assortment of non-traditional
          planning options to accomplish exactly the plan that is best for you
          and your family. This includes special protections and provisions in
          each revocable or irrevocable living trust, last will, power of
          attorney and healthcare documents. Your plan may also include
          incorporating limited liability companies, family limited partnerships
          and strategies for charitable contributions. All of our estate plans
          include the necessary provisions to avoid probate and keep your family
          out of court. Our goal is to minimize any liabilities, such as an
          inheritance or estate tax, as well as provide protection against the
          potentials of a remarriage after the first spouse's death or even the
          future divorce of a beneficiary.
        </p>

        <h2>Family-Owned Businesses, Agriculture Land & Farms</h2>
        <p>
          Yuma is an agriculture community and Deason Garner & Hansen Law Firm
          has been providing the expertise of estate planning that fits the
          needs of family businesses and farms for over 40 years. Our firm knows
          that planning for the future is important and we know how to help you
          create a legacy that will last for generations.
        </p>

        <h2>Incapacity Planning</h2>
        <p>
          Incapacity and disability are more common now than ever. Your plan for
          aging should include an incapacity planning component that empowers
          representatives, of your own choosing, to act on your behalf in the
          event of your incapacity. At Deason Garner & Hansen Law Firm, we build
          planning that is designed to steer clear of the county courthouse and
          avoid legal proceedings such as probate and guardianship.
        </p>

        <h2>IRA & Retirement Planning</h2>
        <p>
          Living on a beach in Mexico? Travelling and visiting family? How do
          you want to spend your retirement years? We can help connect you with
          licensed financial professionals that help you set your sights on your
          retirement goals. Working closely together with you and them, we can
          help your estate plan compliment your retirement goals. Ask us how.
          Your planning can include your IRA and retirement plans. We also
          ensure there are tax reduction techniques as part of the planning.
        </p>

        <h2>Elder Law & Medicaid Services</h2>
        <p>
          Long-term care planning is an important consideration when creating
          your plan. 70% of Americans 65 years old or older will go through some
          period of incapacity needing additional care. This kind of long-term
          care is expensive. In fact, it is a quick way to go bankrupt. Ask us
          how we can help you plan for such an event happening in your life. We
          can help you qualify for the benefits available through the state and
          avoid wasting money and spending through your savings.
        </p>

        <h2>Asset Protection & Business Planning</h2>
        <p>
          A properly drafted Arizona Trust can provide creditor protection for
          future generations. Your beneficiaries' life problems such as
          creditors, divorce, bankruptcy or lawsuits cannot touch what you leave
          to them. Ask us how you can build these asset protection provisions
          into your plan. We also assist with business succession planning and
          small business planning. Some of the tools and strategies used are
          family limited partnerships, limited liability companies and buy-sell
          agreements.
        </p>

        <h2>Estate Planning & Your Pets</h2>
        <p>
          For most, pets are part of the family. Arizona law allows you to make
          provisions for your pets, so that you can have peace of mind knowing
          that your pets will be taken care of in the event of your possible
          incapacity or when you pass away. Ask us how you can make provisions
          for your beloved pets in your plan.
        </p>

        <h2>Special Needs Planning</h2>
        <p>
          Friends and family closest to you may depend on Medicaid and Social
          Security income. An inheritance could jeopardize the benefits. Our
          firm can prepare a special needs trust to preserve the benefits and
          still receive benefit from planning. These tailored plans help
          preserve government benefits while having your loved one cared for in
          all areas of their life: financial, educational, medical and most
          importantly, their emotional and personal needs in the future.
        </p>

        <h2>Trust Administration & Probate</h2>
        <p>
          After you pass away, the business of your estate must be handled. If
          you take the right steps, your heirs will receive their inheritances
          in a timely and efficient manner.
        </p>

        <h2>Financial Planning</h2>
        <p>
          We live in a turbulent world. Sometimes stocks are up, sometimes they
          are down. We can help you plan for your future by recommending
          appropriate financial decisions that will help you meet your financial
          goals and allow you to rest easy knowing that your finances are being
          monitored by licensed professionals.
        </p>

        <p className="font-18 bold">
          For more information on our estate planning services in Yuma, Arizona,
          and to make an appointment with Shawn D. Garner or Adam D. Hansen,
          call our office at (928) 783-4575.
        </p>
      </div>
    </Page>
  );
}

export default About;
