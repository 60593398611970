import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function GuardianshipConservatorship() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="guardianshipAndConservatorship"
      className="generic-page"
      title="Guardianship & Conservatorship"
      description="Learn about guardianship and conservatorship and how our attorneys can help you stay protected"
    >
      <div className="page-content">
        <h1>Guardianship & Conservatorship</h1>
        <p>
          Having a proper plan in place often allows you to avoid the complex
          and time-consuming process of guardianship or conservatorship court
          proceedings. As highly experienced attorneys in this area, the
          attorneys at Deason Garner & Hansen Law Firm can help you with
          guardianship and conservatorship preparation at our law office.
        </p>

        <h2>Do I Need a Guardianship or Conservatorship Plan?</h2>
        <p>
          At some point, you may become incapacitated and unable to make
          decisions regarding your welfare or financial matters. A court
          proceeding, similar to probate, can appoint a guardian or conservator,
          but the process will involve time, energy, and money for court costs
          and attorney's fees.
        </p>
        <p className="fst-italic">
          A guardianship proceeding may be avoided by executing a valid
          healthcare power of attorney, mental healthcare power of attorney, and
          a living will.
        </p>

        <h2>What Is a Guardianship?</h2>
        <p>
          When a person is incapacitated and cannot make reasonable decisions
          regarding his or her welfare, the court will appoint a guardian
          through a guardianship proceeding. The guardian may be appointed in
          general or for a specific purpose and time period. A guardian can be
          nominated by a will or by a petition to the court. The guardianship
          proceeding involves an attorney for the potential ward, a physician's
          report and a court investigator's report in order to determine need
          for a guardian.
        </p>
        <p>
          If the court determines that a guardian is warranted, the court will
          appoint the guardian in conjunction with the best interests of the
          ward. Although the court can take into consideration persons who are
          nominated to be the guardian, the ultimate decision rests on the
          court. The guardian is responsible for making decisions regarding the
          ward's education, living arrangements, social activities, and medical
          or other professional care that is needed.
        </p>

        <h2>What Is a Conservatorship?</h2>
        <p>
          When a person is incapacitated and cannot make reasonable decisions
          regarding his or her property or financial matters, the court will
          appoint a conservator through a conservatorship proceeding. A
          conservatorship proceeding is similar to a guardianship proceeding,
          with the court making the final determination as to who will act as
          the conservator. The conservatorship can be general, limited or for a
          single transaction. A general conservator has a wide range of powers
          over the protected person's assets such as:
        </p>
        <ul>
          <li className="fw-normal">Checking accounts</li>
          <li className="fw-normal">Businesses</li>
          <li className="fw-normal">Brokerage accounts</li>
          <li className="fw-normal">Real estate</li>
          <li className="fw-normal">Livestock</li>
          <li className="fw-normal">Vehicles</li>
        </ul>
        <p>
          With proper planning, expensive and time-consuming court proceedings
          can often be avoided.
        </p>

        <h2>How to Avoid Court Proceedings</h2>
        <p>
          Guardianship and conservatorship proceedings are time-consuming,
          expensive and require extensive work on the part of the guardian or
          conservator. Additionally, many important decisions are left up to the
          court, not to you or your family. With proper planning, these court
          proceedings can often be avoided.
        </p>
        <p>
          A guardianship proceeding may be avoided by executing a valid
          healthcare power of attorney, mental healthcare power of attorney, and
          a living will while you are competent. These documents will give the
          power of attorney agent the ability to provide for your welfare should
          you become incapacitated. A conservatorship proceeding may be avoided
          by executing a valid durable financial power of attorney to manage
          your assets in the event you become incapacitated. Again, this power
          of attorney must be executed while you are a competent adult.
        </p>
        <p>
          Accordingly, by planning ahead using the experience of our attorneys,
          you can potentially avoid unnecessary court hassles and expenses. Our
          attorneys will do all they can to help you avoid a guardianship or
          conservatorship proceeding. In the event, however, that proceedings
          cannot be avoided, they will use their experience to assist you
          throughout the process.
        </p>

        <h2>Schedule Your Consultation</h2>
        <p>
          To find out more about avoiding guardianships and conservatorships in
          Yuma, Arizona, while having your health and financial needs met,
          contact our office at (928) 783-4575 for an appointment with Shawn D.
          Garner and Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default GuardianshipConservatorship;
