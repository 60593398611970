import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function GunTrusts() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="gunTrusts"
      className="generic-page"
      title="Gun Trusts"
      description="Learn more about how a gun trust protects your firearms from going through the probate process"
    >
      <div className="page-content">
        <h1>Gun Trusts</h1>
        <p className="subheader">What can I do with a gun trust?</p>

        <h2>NFA weapons</h2>
        <p>
          Machine guns, sound suppressors (a.k.a. silencers), short-barreled
          shotguns, short-barreled rifles, destructive devices and "any other
          weapons."
        </p>

        <h2>Non-NFA (or GCA) weapons</h2>
        <p>All firearms and weapons that are not NFA weapons.</p>

        <h2>Avoid Probate and Publicity About Your Firearms</h2>
        <p>
          A gun trust protects your firearms from going through the probate
          process, a court proceeding to administer a deceased person's assets.
          If you have a will or if you have no estate planning documents in
          place, a probate may result. Remember, probate can make your guns and
          other assets publicly available, so you may want to use a gun trust to
          bypass it. A gun trust helps you to pass on your firearms to those you
          want to have and enjoy them.
        </p>

        <h2>Protect, Preserve and Pass On Your Firearms Legacy</h2>
        <p>
          Legacy planning starts with planning for your own needs first. Then
          you plan for the needs of others. In this way you can effectively make
          a difference. We help others best when we are on a solid personal
          footing.
        </p>
        <p>
          It is clear that firearms are an important part of your life. A gun
          trust is a written plan to help you and those you care about to
          lawfully enjoy your firearms during your lifetime. Upon your death,
          the memories you created can be passed on, honored, and continued by
          those you select to receive your firearms.
        </p>
        <p>
          Seven out of 10 Americans have no estate plan for their assets, and a
          gun trust is one piece of a greater whole. Planning for your firearms
          is a way to protect, preserve and pass on something that is important
          to you. But remember, if you have no estate plan for other assets, or
          if you have a will-based plan, a public probate is likely to occur.
          Probate can make private information public.
        </p>
        <p>
          If you have done your estate planning, congratulations! If not, let
          this effort serve to motivate you to intentionally plan for and share
          what you have built up over your lifetime. Contact us at (928)
          783-4575 to make your appointment with our attorneys, Shawn D. Garner
          and Adam D. Hansen, and get started on making your gun trust in Yuma,
          Arizona.
        </p>

        <h2>Lawfully "Share" Your Firearms</h2>
        <p>
          Generally, a gun trust is a superior way to acquire and possess
          firearms and accessories and to share them with some protection
          against commission of an "accidental felony." In other words, an
          unlawful transfer made resulting in unlawful possession and transfer
          of a firearm when you and the other person truly believed you were
          acting lawfully.
        </p>
        <p>
          Why is a gun trust so useful? You can document why somebody other than
          you is in possession of your firearm. Both federal and state law can
          make it criminal for another person to possess your firearm. Title I
          firearms have different requirements than Title II firearms, and each
          state may also have its own requirements.
        </p>
        <p>
          Several states are prime examples of transfer laws that can be
          surprising to gun owners, such as Washington, Oregon and Colorado.
          These and other states impose criminal penalties for the transfer of a
          firearm between persons absent a background check or a statutory
          exclusion or exception.
        </p>

        <h2>Make Specific Gifts of Firearms to Individuals</h2>
        <p>
          A gun trust allows you to distribute your firearms by using a written
          memorandum. The Firearms and Accessories Memorandum (a customized
          personal property memorandum) allows you to designate a beneficiary of
          a specific firearm or accessory.
        </p>

        <h2>
          Acquiring and Possessing Rifles, Pistols and Shotgun (Non-NFA or GCA)
          Firearms
        </h2>
        <p>
          A trust is not a person under Title I of the Gun Control Act, so no
          background check can be performed at an FFL for a direct purchase by a
          trust or trustee. This is different than for an NFA firearm under
          Title II. However, a trust can acquire non-NFA firearms directly from
          a private party if no FFL or background check is required and it can
          possess firearms lawfully transferred to it with an assignment or bill
          of sale.
        </p>

        <h2>Acquiring and Possessing NFA Items</h2>
        <p>
          Prior to July 13, 2016, using a trust expedited the process for
          purchasing NFA firearms like silencers (suppressors). However, the
          Obama Administration changed the rules regarding gun trusts on July
          13, 2016 which made gun trusts subject to many of the same
          requirements as purchasing NFA firearms as an individual. Federal law
          now requires each "Responsible Person" to provide documentation when
          filing an application to receive (Form 4) an NFA Firearm. First, this
          requires the Grantor/Settlor of a gun trust, each serving Trustee, and
          anyone having certain power or authority over the Gun trust and Trust
          property to provide a Chief Law Enforcement Officer (CLEO)
          notification first. Second, the full application is submitted to NFA
          Branch and a background check is done prior to processing your
          application.
        </p>

        <h2>BATFE Forms Processing</h2>
        <p>
          In accordance with the ATF Final Rule 41F which was effective July 13,
          2016, the Chief Law Enforcement Officer (CLEO) certification is no
          longer required on following Bureau of Alcohol, Tobacco, Firearms and
          Explosives (BATFE):
        </p>
        <ul>
          <li>
            ATF Form 1 (5320.1): Application to Make and Register a Firearm
          </li>
          <li>
            ATF Form 4 (5320.4): Application for Tax Paid Transfer and
            Registration of a Firearm
          </li>
          <li>
            ATF Form 5 (5320.5): Application for Tax Exempt Transfer and
            Registration of Firearm
          </li>
        </ul>

        <h2>Requirements</h2>
        <p>
          Final Rule 41F replaces the CLEO certification with CLEO notification
          which requires that a completed copy of the Form 1, Form 4 and Form 5
          applications, and the specified form for responsible persons (Form
          5320.23), be forwarded to the CLEO of the locality in which the
          applicant/transferee or responsible person resides.
        </p>

        <h2>Yuma County CLEO Delivery and Mailing Address</h2>
        <p>
          Completed CLEO (Chief Law Enforcement Officer) copies for all of the
          above listed forms may be delivered or mailed to the following
          address:
        </p>
        <p>
          Yuma County Sheriff's Office
          <br />
          Attn: Sheriff Leon N. Wilmot
          <br />
          141 S. 3rd Avenue
          <br />
          Yuma AZ 85364
        </p>
      </div>
    </Page>
  );
}

export default GunTrusts;
