import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function PowerOfAttorney() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="powerOfAttorney"
      className="generic-page"
      title="Power of Attorney"
      description="Learn more about how power of attorney can ensure that your wishes are carried out in the case of incapacitation"
    >
      <div className="page-content">
        <h1>Power of Attorney</h1>
        <p className="subheader">
          Understanding the importance of Power of Attorney
        </p>
        <p>
          An incapacity plan is a collection of legal tools that serve as a
          safety net against the possibility that you might one day lose your
          ability to make choices. Incapacity planning gives you, and your
          family, reassurance that should the worst happen to you, you will
          leave clear instructions about your wishes.
        </p>

        <h2>Medical Decisions</h2>
        <p>
          One of the more pressing concerns that incapacity planning addresses
          is the question of the types of medical care you do or do not wish to
          receive should you lose your ability to communicate.
        </p>
        <ul>
          <li className="fw-normal">
            Do you want to receive CPR if you are in the final stages of a
            terminal illness?
          </li>
          <li className="fw-normal">
            Do you want your doctors to take heroic measures to maintain or
            prolong your life?
          </li>
          <li className="fw-normal">
            Are there situations in which you would want a type of care, but
            other situations in which you wouldn't?
          </li>
        </ul>
        <p>
          All of these questions lie at the heart of incapacity planning. By
          creating various types of advance medical directives, you can maintain
          control over the types of medical care you would want to receive
          should you become incapacitated. Your incapacity plan will also allow
          you to appoint someone who will be able to communicate with your
          doctors and make decisions on your behalf.
        </p>

        <div
          className="d-flex"
          style={{
            width: "100%",
            height: "300px",
          }}
        >
          <iframe
            style={{ flexGrow: "1" }}
            src="https://www.youtube.com/embed/nJ9swK6RAG4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <h2>Financial Responsibilities</h2>
        <p>
          If you become incapacitated tomorrow, who will pay your bills for you?
          Who will look after your property? Who will be responsible for
          contacting your bank, business partners or anyone else with whom you
          have financial dealings?
        </p>
        <p>
          It's important that your incapacity plan address questions like these
          in addition to any medical choices or decisions. Regardless of your
          individual circumstances, each of us have personal and financial
          responsibilities that must be managed should we become incapacitated.
          Without the proper plan in place, we have no control over who will
          manage these affairs on our behalf.
        </p>
        <p>
          An incapacity plan will include various tools to address the personal
          and financial responsibilities you need addressed. An example is the
          financial powers of attorney that allows you to choose an agent who
          will represent your interests. Your agent, under power of attorney,
          will be able to step in to begin managing your responsibilities.
        </p>

        <h2>Caring for Children</h2>
        <p>
          What happen to your children if you and your spouse were involved in a
          car accident tomorrow? Who would take care of your children? What
          about financial needs? How would you make sure your children receive
          the care they need?
        </p>
        <p>
          If you haven't already done so, creating an incapacity plan that
          chooses a guardian for your children is an essential step to take if
          you have young children.
        </p>

        <h2>Making Individual Choices</h2>
        <p>
          Though the tools involved in incapacity planning are the same for
          everyone, the choices you get to make are entirely yours. The
          incapacity planning process is designed to allow you to create a plan
          that meets your individual desires and circumstances. If you haven't
          already created a plan, you can learn more at our Free Monthly
          Seminars, or contact Deason Garner & Hansen Law Firm at (928) 783-4575
          as soon as possible! We will help you make an appointment with our
          attorneys, Shawn D. Garner or Adam D. Hansen so that you can learn
          more about incapacity planning and power of attorney in Yuma, Arizona.
        </p>
      </div>
    </Page>
  );
}

export default PowerOfAttorney;
