import React from "react";

function ApptScheduler() {
  return (
    <React.Fragment>
      <iframe
        src="https://app.squarespacescheduling.com/schedule.php?owner=22156098"
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      ></script>
    </React.Fragment>
  );
}

export default ApptScheduler;
