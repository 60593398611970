import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import pdf from "../../../assets/Essential_elements_of_estate_planning.pdf";

import "../../../styles/components/pages/generic-page.scss";

function EstatePlanning() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      className="generic-page"
      title="Estate Planning"
      description="We know estate planning like the back of our hand! Regardless of where you are in the process, we can help."
    >
      <div className="page-content">
        <h1>Estate Planning in Yuma, Arizona</h1>
        <p>
          Whether you are old, young, married, single, wealthy or just starting
          out in life, you need an estate plan. Creating an estate plan is
          something that every responsible adult needs to take the time to do.
          Only through your estate plan can you make choices about important
          issues that will affect you and your family, both now, and in the
          future. A good plan is something that will change with you over time
          and give you options that you might not have realized you needed.
        </p>
        <div
          className="d-flex"
          style={{
            width: "100%",
            height: "300px",
          }}
        >
          <iframe
            style={{ flexGrow: "1" }}
            src="https://www.youtube.com/embed/Ex-wuqoCq5I"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <h2>What Does Estate Planning Entail?</h2>
        <p>
          An “estate” is the legal term for the collection of property and
          concerns you leave behind after you die. Estate planning, therefore,
          is making choices about what you want to happen to your affairs, and
          then making sure that you make those choices in a legally recognized
          manner. Estate plans address numerous issues and include a range of
          legal tools.
        </p>
        <p>Some of estate planning issues include:</p>
        <ul>
          <li>
            Last Will and Testament.{" "}
            <span>
              The most basic, and essential, of all estate planning tools is the
              last will and testament. As a legal document, your will must meet
              the requirements imposed under the laws of the state of Arizona.
              Your will allows you to make inheritance decisions, as well as few
              other important choices. If, after creating a will, you change
              your mind about the decisions you made, you are free to update or
              revise your will whenever you choose.
            </span>
          </li>
          <li className="pt-3">
            Powers of Attorney.{" "}
            <span>
              There may come a time when you need someone else to make decisions
              on your behalf, or to represent your interests. Because of this,
              estate plans typically include one or more powers of attorney.
              Through your power of attorney you can name an agent to represent
              your interests and make decisions for you if and when you lose
              your ability to communicate or make reasonable choices.
            </span>
          </li>
          <li className="pt-3">
            Advance Medical Directives.{" "}
            <span>
              Should you become incapacitated, you will also want to be sure
              that your doctors will follow your medical wishes. To do this you
              will have to create advance medical directives, such as a living
              will or healthcare power of attorney. Through these documents you
              can be as clear as you like when it comes to accepting or refusing
              medical care in the event you are no longer able to make choices
              or communicate.
            </span>
          </li>
        </ul>

        <div
          className="d-flex"
          style={{
            width: "100%",
            height: "300px",
          }}
        >
          <iframe
            style={{ flexGrow: "1" }}
            src="https://www.youtube.com/embed/nJ9swK6RAG4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>

        <h2>Crafting a Plan with Your Attorney</h2>
        <p>
          Every estate plan has to be individually tailored to meet the needs of
          the person making it. Knowing what you need, what steps you have to
          take and what your options are is only possible by seeking the advice
          of an experienced estate planning lawyer.
        </p>
        <p>
          If you haven't already done so, contact Deason Garner & Hansen Law
          Firm immediately at (928) 783-4575 in order to begin the estate
          planning process with our attorneys, Shawn D. Garner and Adam D.
          Hansen. Our firm hosts monthly free estate planning seminars to share
          information about estate planning in Yuma, Arizona. Our firm is here
          to help you make the first step towards protecting your assets and
          your legacy!
        </p>
        <p>
          Click{" "}
          <a href={pdf} target="_blank" rel="noreferrer" className="bold">
            here
          </a>{" "}
          to download and read more about the essential elements of estate
          planning in Arizona.
        </p>

        <h2>Learn More About Estate Planning Topics</h2>
        <ul>
          <Link to="/estate-planning/asset-protection">
            <li>Asset Protection</li>
          </Link>
          <Link to="/estate-planning/business-planning">
            <li>Business Planning</li>
          </Link>
          <Link to="/estate-planning/estate-tax-exclusions">
            <li>Estate Tax Exclusions</li>
          </Link>
          <Link to="/estate-planning/family-owned-business">
            <li>Family-Owned Business</li>
          </Link>
          <Link to="/estate-planning/fiduciary-services">
            <li>Fiduciary Services</li>
          </Link>
          <Link to="/estate-planning/gun-trusts">
            <li>Gun Trusts</li>
          </Link>
          <Link to="/estate-planning/living-trust">
            <li>Living Trust</li>
          </Link>
          <Link to="/estate-planning/power-of-attorney">
            <li>Power of Attorney</li>
          </Link>
          <Link to="/estate-planning/probate">
            <li>Probate</li>
          </Link>
          <Link to="/estate-planning/retirement-planning">
            <li>Retirement Planning</li>
          </Link>
          <Link to="/estate-planning/special-needs-trust">
            <li>Special Needs Trust</li>
          </Link>
          <Link to="/estate-planning/wills">
            <li>Wills</li>
          </Link>
        </ul>
      </div>
    </Page>
  );
}

export default EstatePlanning;
