import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import kbluLogo from "../../../assets/kblu-logo.webp";
import radioShow from "../../../assets/radio-show.webp";

import { ReactComponent as VolumeIcon } from "../../../assets/volume-icon.svg";

function RadioShow() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="about"
      selectedLevel2="lifeDeathLaw"
      className="generic-page"
      title="Life, Death, & the Law"
      description="Join our weekly radio show to learn more about various estate planning and elder law topics free of charge"
    >
      <div className="page-content">
        <h1>Life, Death & the Law - Radio Show/Podcast</h1>
        <p>
          Deason Garner & Hansen Law Firm wants to help you understand how best
          to plan for your future, wherever you are in life. That is why we run
          our own weekly radio show for you to enjoy free of charge. Check back
          often to learn more about estate planning, elder law and more in Yuma,
          Arizona, and call us at (928) 783-4575 to learn more.
        </p>
        <div className="d-flex flex-column" style={{ maxWidth: "700px" }}>
          <img
            src={radioShow}
            width="100%"
            alt="Attorneys Shawn Garner and Adam Hansen"
          />
        </div>

        <h2>
          Tune in to KBLU 560 AM on Mondays at 8am to listen to the show live on
          the radio
        </h2>
        <div className="d-flex align-items-center mt-4">
          <img
            src={kbluLogo}
            alt="Attorneys Shawn Garner and Adam Hansen"
            height="50px"
          />
          <button
            className="ms-4 btn-filled-tertiary font-18 p-4"
            onClick={() => window.open("https://v7player.wostreaming.net/1031")}
          >
            <VolumeIcon />
            Listen Live
          </button>
        </div>

        <h2>Listen to previous episodes commerical-free on our podcast</h2>
        <button
          className="mt-4 align-self-center btn-filled-tertiary font-18 p-4"
          onClick={() => window.open("https://rss.com/podcasts/lifedeathlaw/")}
        >
          <VolumeIcon />
          Podcast
        </button>
      </div>
    </Page>
  );
}

export default RadioShow;
