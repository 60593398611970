import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./components/pages/home";

import About from "./components/pages/about/about";
import EstatePlanningSeminars from "./components/pages/about/estatePlanningSeminars";
import MeetOurAttorneys from "./components/pages/about/meetOurAttorneys";
import AdamHansen from "./components/pages/about/adamHansen";
import ShawnGarner from "./components/pages/about/shawnGarner";
import LarryDeason from "./components/pages/about/larryDeason";
import MeetOurTeam from "./components/pages/about/meetOurTeam";
import RadioShow from "./components/pages/about/radioShow";
import Reviews from "./components/pages/about/reviews";
import WhyWorkWithUs from "./components/pages/about/whyWorkWithUs";

import EstatePlanning from "./components/pages/estatePlanning/estatePlanning";
import AssetProtection from "./components/pages/estatePlanning/assetProtection";
import BusinessPlanning from "./components/pages/estatePlanning/businessPlanning";
import EstateTaxExclusions from "./components/pages/estatePlanning/estateTaxExclusions";
import FamilyOwnedBusiness from "./components/pages/estatePlanning/familyOwnedBusiness";
import FiduciaryServices from "./components/pages/estatePlanning/fiduciaryServices";
import GunTrusts from "./components/pages/estatePlanning/gunTrusts";
import LivingTrust from "./components/pages/estatePlanning/livingTrust";
import TrustAdministration from "./components/pages/estatePlanning/trustAdministration";
import PowerOfAttorney from "./components/pages/estatePlanning/powerOfAttorney";
import Probate from "./components/pages/estatePlanning/probate";
import ProbateFAQ from "./components/pages/estatePlanning/probateFAQ";
import RetirementPlanning from "./components/pages/estatePlanning/retirementPlanning";
import SpecialNeedsTrust from "./components/pages/estatePlanning/specialNeedsTrust";
import Wills from "./components/pages/estatePlanning/wills";

import ElderLaw from "./components/pages/elderLaw/elderLaw";
import GuardianshipConservatorship from "./components/pages/elderLaw/guardianshipConservatorship";
import HealthcareDecisions from "./components/pages/elderLaw/healthcareDecisions";
import LongTermCare from "./components/pages/elderLaw/longTermCare";
import MedicaidPlanning from "./components/pages/elderLaw/medicaidPlanning";
import SocialSecurity from "./components/pages/elderLaw/socialSecurity";
import VeteransBenefits from "./components/pages/elderLaw/veteransBenefits";

import ContactUs from "./components/pages/contactUs";
import PrivacyPolicy from "./components/pages/privacyPolicy";

import "./styles/style.scss";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/about" element={<About />} />
        <Route
          exact
          path="/about/estate-planning-seminars"
          element={<EstatePlanningSeminars />}
        />
        <Route
          exact
          path="/about/meet-our-attorneys"
          element={<MeetOurAttorneys />}
        />
        <Route
          exact
          path="/about/meet-our-attorneys/adam-hansen"
          element={<AdamHansen />}
        />
        <Route
          exact
          path="/about/meet-our-attorneys/shawn-garner"
          element={<ShawnGarner />}
        />
        <Route
          exact
          path="/about/meet-our-attorneys/larry-deason"
          element={<LarryDeason />}
        />
        <Route exact path="/about/meet-our-team" element={<MeetOurTeam />} />
        <Route
          exact
          path="/about/life-death-and-the-law"
          element={<RadioShow />}
        />
        <Route exact path="/about/reviews" element={<Reviews />} />
        <Route
          exact
          path="/about/why-work-with-us"
          element={<WhyWorkWithUs />}
        />
        <Route exact path="/estate-planning" element={<EstatePlanning />} />
        <Route
          exact
          path="/estate-planning/asset-protection"
          element={<AssetProtection />}
        />
        <Route
          exact
          path="/estate-planning/business-planning"
          element={<BusinessPlanning />}
        />
        <Route
          exact
          path="/estate-planning/estate-tax-exclusions"
          element={<EstateTaxExclusions />}
        />
        <Route
          exact
          path="/estate-planning/family-owned-business"
          element={<FamilyOwnedBusiness />}
        />
        <Route
          exact
          path="/estate-planning/fiduciary-services"
          element={<FiduciaryServices />}
        />
        <Route
          exact
          path="/estate-planning/gun-trusts"
          element={<GunTrusts />}
        />
        <Route
          exact
          path="/estate-planning/living-trust"
          element={<LivingTrust />}
        />
        <Route
          exact
          path="/estate-planning/trust-administration"
          element={<TrustAdministration />}
        />
        <Route
          exact
          path="/estate-planning/power-of-attorney"
          element={<PowerOfAttorney />}
        />
        <Route exact path="/estate-planning/probate" element={<Probate />} />
        <Route
          exact
          path="/estate-planning/probate-faq"
          element={<ProbateFAQ />}
        />
        <Route
          exact
          path="/estate-planning/retirement-planning"
          element={<RetirementPlanning />}
        />
        <Route
          exact
          path="/estate-planning/special-needs-trust"
          element={<SpecialNeedsTrust />}
        />
        <Route exact path="/estate-planning/wills" element={<Wills />} />
        <Route exact path="/elder-law" element={<ElderLaw />} />
        <Route
          exact
          path="/elder-law/guardianship-and-conservatorship"
          element={<GuardianshipConservatorship />}
        />
        <Route
          exact
          path="/elder-law/healthcare-decisions"
          element={<HealthcareDecisions />}
        />
        <Route
          exact
          path="/elder-law/long-term-care"
          element={<LongTermCare />}
        />
        <Route
          exact
          path="/elder-law/medicaid-planning"
          element={<MedicaidPlanning />}
        />
        <Route
          exact
          path="/elder-law/social-security"
          element={<SocialSecurity />}
        />
        <Route
          exact
          path="/elder-law/veterans-benefits"
          element={<VeteransBenefits />}
        />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
