import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import pdf from "../../../assets/What_is_a_revocable_living_trust.pdf";

import "../../../styles/components/pages/generic-page.scss";

function LivingTrust() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="livingTrust"
      className="generic-page"
      title="Living Trust"
      description="Learn more about living trusts and how our attorneys can help you through the next steps."
    >
      <div className="page-content">
        <h1>Living Trust</h1>
        <p className="subheader">
          Advice from an experienced living trust attorney
        </p>
        <p>
          Do you know what to do when you have lost a loved one? We can help!
          There is a lot of information to follow, so take it one step at a
          time. The most important thing to do is take time to celebrate the
          life of a special person, then allow us to help you with the next
          step. A living trust attorney at Deason Garner & Hansen Law Firm can
          provide experienced guidance throughout the entire process.
        </p>
        <p className="fst-italic">
          The most important thing to do is take time to celebrate the life of a
          special person, then allow us to help you with the next step.
        </p>

        <h2>If the Person Passed Away With NO Estate Planning</h2>
        <p>
          If someone passes away without estate planning, it is called "dying
          intestate." The assets in a person's name will be called the "estate"
          and will be subject to state intestacy laws and go through probate
          court.
        </p>

        <h2>If the Person Passed With a WILL</h2>
        <p>
          The only way a will has any legal effect or validity is through the
          probate court. It MUST be submitted through probate. A will ONLY works
          through probate. The probate process requires a court to take over the
          “estate” to be sure debts are paid, loose ends are taken care of and
          for assets to be distributed. A personal representative is appointed
          and their role manages the very complex and long process.
        </p>

        <h2>If Your Loved One Created a Living Trust</h2>
        <p>
          The process to be followed after someone has passed with a living
          trust is called the Trust Administration. Upon the death, the
          appointed successor trustee steps in to follow the instructions
          according to the trust. This is a completely PRIVATE process that does
          NOT include the court. Our firm serves as counsel to the trustee to
          assist with the duties and interpretations of the documents. When a
          trust is not administered properly, the trustee runs the risk of
          causing the beneficiaries to pay penalties or additional fees.
        </p>
        <p>
          Click <Link to="/estate-planning/trust-administration">here</Link> to
          learn more about Trust Administration
        </p>

        <h2>Important Note!</h2>
        <p>
          Do not re-title any assets before speaking with a qualified estate
          planning attorney. That meeting should take place approximately two
          weeks after your loved one has passed. When dealing with a living
          trust, Yuma, Arizona, residents trust Deason Garner & Hansen Law Firm.
          Get in touch with us at (928) 783-4575 to make an appointment with our
          attorneys, Shawn D. Garner and Adam D. Hansen. We can help!
        </p>
        <p>
          Click{" "}
          <a href={pdf} target="_blank" rel="noreferrer" className="bold">
            here
          </a>{" "}
          to download and read more about revocable living trusts in Arizona.
        </p>
      </div>
    </Page>
  );
}

export default LivingTrust;
