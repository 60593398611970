import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import ContactNow from "../common/contactNow";
import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/pages/generic-page.scss";

function ContactUs() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  const hours = (
    <div>
      <h2>Hours</h2>
      <table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Monday</td>
            <td>9:00 a.m. - 5:00 p.m.</td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td>9:00 a.m. - 5:00 p.m.</td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td>9:00 a.m. - 5:00 p.m.</td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td>9:00 a.m. - 5:00 p.m.</td>
          </tr>
          <tr>
            <td>Friday</td>
            <td>9:00 a.m. - 12:00 p.m.</td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td>Closed</td>
          </tr>
          <tr>
            <td>Sunday</td>
            <td>Closed</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const contactInfo = (
    <div>
      <h2>Contact</h2>
      <p>(928) 783-4575</p>
      <p>
        6024 E. 32nd St.
        <br />
        Yuma, AZ 85365
      </p>
      <ContactNow darkmode />
    </div>
  );

  return (
    <Page
      selectedTab="contactUs"
      className="generic-page"
      title="Contact Us"
      description="We are dedicated to helping with all your estate planning needs in Yuma, Arizona. Contact us today to schedule a consultation!"
    >
      <div className="page-content">
        <h1>Contact Your Estate Planning Attorneys in Yuma, Arizona</h1>
        <p>
          Thank you for visiting Deason Garner & Hansen Law Firm online! We are
          dedicated to helping with all your estate planning needs in Yuma,
          Arizona, and the surrounding areas. Our law office offers a full range
          of legal services to ensure that your wishes are carried out and that
          you and your loved ones can receive full benefits. We invite you to
          contact us today to learn more and schedule a consultation with Shawn
          D. Garner and Adam D. Hansen.
        </p>

        <p className="fst-italic">
          Watch our{" "}
          <Link to="/about/estate-planning-seminars">seminar video</Link> to
          receive a $200 credit at our law firm!
        </p>

        <Mobile>
          {hours}
          <div className="mb-5">{contactInfo}</div>
        </Mobile>
        <NotMobile>
          <div className="d-flex mb-5">
            <div className="w-50">{hours}</div>
            {contactInfo}
          </div>
        </NotMobile>

        <div style={{ lineHeight: "0" }}>
          <iframe
            title="Deason Garner & Hansen Law Firm Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.578555886962!2d-114.53176268431359!3d32.67065749670713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d658adaf9e08b5%3A0x62a4c6174e1015f1!2sDeason%20Garner%20%26%20Hansen%20Law%20Firm!5e0!3m2!1sen!2sus!4v1659742612308!5m2!1sen!2sus"
            width="100%"
            height="600px"
            allowFullScreen=""
            loading="lazy"
          />
        </div>
      </div>
    </Page>
  );
}

export default ContactUs;
