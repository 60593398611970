import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function HealthcareDecisions() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="healthcareDecisions"
      className="generic-page"
      title="Healthcare Decisions"
      description="Learn about how healthcare directives can protect your medical wishes if you were to become incapacitated"
    >
      <div className="page-content">
        <h1>Healthcare Decisions</h1>
        <p className="subheader">
          What you need to know about healthcare directives
        </p>
        <p>
          If you were to become incapacitated today, do you know who would make
          healthcare decisions on your behalf? With proper planning, you can
          choose someone who clearly understands your wishes and instructions,
          and who is willing and capable of making those decisions for you. With
          their expertise in this area, the Deason Garner & Hansen Law Firm
          attorneys will help you plan for unforeseen circumstances with advance
          healthcare directives.
        </p>
        <p className="fst-italic">
          A living will is a document that allows you to state your desires
          regarding life-sustaining treatment if you should become terminally
          ill or fall into a permanent coma or vegetative state.
        </p>

        <h2>Why You Need to Plan for Your Healthcare</h2>
        <p>
          Now is the best time to determine who will make your healthcare
          decisions when you cannot, especially if you are currently relatively
          young and healthy. You can make better decisions about what you would
          like today, before you are faced with a debilitating injury or failing
          health. If your health is already failing, it is still best to select
          and talk about your wishes with your chosen confidant now, rather than
          later. In order to be legally binding, our attorneys can help you
          create the necessary documents.
        </p>

        <h2>Why You Need to Plan for Your Healthcare</h2>
        <p>
          Our attorneys will assist you in preparing and executing a valid
          healthcare{" "}
          <Link to="/estate-planning/power-of-attorney">power of attorney</Link>
          , mental healthcare power of attorney, and living will. The healthcare
          power of attorney will allow the person you designate (agent) to make
          the decisions regarding your healthcare, including whether or not to
          continue life-sustaining treatment if you are unable to communicate
          your wishes. In addition to executing this document, you should
          discuss your wishes with your agent so that he or she completely
          understands your desires.
        </p>
        <p>
          In Arizona, a healthcare power of attorney does not allow the agent to
          make decisions regarding your mental health, especially if you require
          admission to a Level 1 behavioral health facility. Persons with
          Alzheimer's or other types of dementia often have behavioral issues
          and may need hospitalization. Without a mental healthcare power of
          attorney, the only option for a concerned party would be to file for
          an emergency guardianship. The mental healthcare power of attorney
          will allow your agent to make the necessary decisions regarding your
          mental healthcare.
        </p>
        <p>
          A living will is a document that allows you to state your desires
          regarding life-sustaining treatment if you should become terminally
          ill or fall into a permanent coma or vegetative state. Our attorneys
          will make sure that your wishes are properly expressed in these
          documents and that the documents are validly executed.
        </p>

        <h2>Schedule Your Consultation Today</h2>
        <p>
          To learn more about how to ensure that your healthcare decisions in
          Yuma, Arizona, are carried out if you become incapacitated, contact us
          today at (928) 783-4575 to make an appointment with Shawn D. Garner or
          Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default HealthcareDecisions;
