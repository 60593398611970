import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function VeteransBenefits() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="elderLaw"
      selectedLevel2="veteransBenefits"
      className="generic-page"
      title="Veterans' Benefits"
      description="If you are a veteran, learn more about how to take full advantage of the benefits you are entitled to"
    >
      <div className="page-content">
        <h1>Veterans' Benefits</h1>
        <p>
          A veterans' benefits attorney at Deason Garner & Hansen Law Firm can
          provide experienced assistance in navigating governmental paperwork to
          ensure you receive the benefits you have earned by serving your
          country. Our attorneys appreciate the freedoms your service has
          provided. We do our part to ensure your right to benefits is protected
          following your service. Whether you are a disabled veteran or are in
          need of basic benefits, we are here to help.
        </p>
        <p>
          An estate planning attorney can help you secure the benefits to which
          you are entitled so that you can lead a comfortable life after
          military service.
        </p>

        <h2>Benefits Provided by the U.S. Department of Veterans Affairs</h2>
        <p>
          The U.S. Department of Veterans Affairs provides various benefits to
          eligible veterans and their families through the Veterans Benefits
          Administration. Benefits offered include:
        </p>
        <ul>
          <li className="fw-normal">
            Medical care, including care for disabled veterans
          </li>
          <li className="fw-normal">
            Pensions for veterans and survivors to help ease financial burdens
          </li>
          <li className="fw-normal">
            Education and training compensation for veterans wanting to attend
            college or a vocational school
          </li>
          <li className="fw-normal">
            Burial assistance benefits extended to veterans, spouses, and
            dependents desiring burial in a national cemetery
          </li>
        </ul>

        <h2>Eligibility for VA Benefits</h2>
        <p>To qualify for VA benefits and pensions, a veteran must:</p>
        <ul>
          <li className="fw-normal">
            Have served a minimum of 90 days in active military
          </li>
          <li className="fw-normal">
            Have served at least one day during a period of war identified by
            the VA
          </li>
          <li className="fw-normal">
            Have been discharged under conditions other than dishonorable
          </li>
          <li className="fw-normal">Be 65 years of age or older</li>
          <li className="fw-normal">Or be permanently and totally disabled</li>
        </ul>

        <h2>Types of Pensions</h2>
        <p>
          There are different types of pensions for which a veteran can qualify,
          including:
        </p>
        <ul>
          <li className="fw-normal">Low income or basic pension</li>
          <li className="fw-normal">Housebound benefits</li>
          <li className="fw-normal">Aid and attendance</li>
          <li className="fw-normal">VA disability compensation</li>
        </ul>
        <p>
          These pensions are paid in addition to the standard monthly pensions
          veterans may receive. These pensions can help offset the costs of
          living or medical care.
        </p>

        <h2>How Our Veterans' Benefits Attorneys Can Help</h2>
        <p>
          The attorneys have a thorough understanding of the many benefits the
          VA provides. Our attorneys also understand the many requirements a
          veteran must meet to receive full benefits. We can help you gather the
          right documentation and paperwork needed for the various benefits
          applications. We can also review any potential interference with
          Medicaid and other such programs. When needed, our attorneys can
          revise your estate plan or create a new one to ensure you receive all
          the benefits you are due. Call us at (928) 783-4575 today.
        </p>
        <p>
          The application processes for VA benefits are complex and often
          confusing. Our attorneys are happy to help you navigate these
          complexities. We can offer tools and strategies to help you maximize
          your benefits. This can ensure you have the compensation you need to
          lead a full and comfortable life after your military service.
        </p>

        <h2>Get the Most Out of Your VA Benefits</h2>
        <p>
          We appreciate each and every veterans service to our country. To help
          show our appreciation, we do our part to help veterans apply for and
          obtain the benefits they have earned through their service. If you are
          a veteran and would like help understanding the various benefits
          available to you in Yuma, Arizona, contact us today to schedule a
          consultation with Shawn D. Garner or Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default VeteransBenefits;
