import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../common/page";

import "../../styles/components/pages/generic-page.scss";

function PrivacyPolicy() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      className="generic-page"
      title="Privacy Policy"
      description="Privacy Policy for Deason Garner & Hansen Law Firm Website"
    >
      <div className="page-content">
        <h1>Privacy Policy</h1>
        <p>
          Thank you for visiting our website. Deason Garner & Hansen Law Firm is
          committed to respecting your online privacy. The following privacy
          policy sets forth how we use personal information collected at this
          site. Please read the entire privacy policy before using the site or
          submitting any of your personal information. When you use the site,
          you are accepting the practices described in this policy. The privacy
          practices may be changed in the future. Any changes will be posted and
          changes will only apply to activities and information in the future,
          not retroactively. Please review the privacy policy every time you
          visit our site to understand how personal information you provide will
          be used.
        </p>

        <h2>Collected Information</h2>
        <p>
          We collect personal information such as names, phone numbers, email
          addresses, etc., only when voluntarily submitted by our visitors. The
          information you provide is only used to fulfill your particular
          request unless you give us permission to use it in another manner.
        </p>

        <h2>Shared Information</h2>
        <p>
          We may share information with government agencies or any other
          companies assisting us in fraud prevention or investigation. We may do
          so when permitted or required by law or when investigating fraud that
          has already taken place. We also may use other businesses to perform
          certain services for us, such as maintaining our site and our email
          services and processing inquiries. We may provide personal information
          to those businesses when that information is necessary for them to
          complete a requested transaction or otherwise perform their duties. We
          will not sell or share your personal information with any unaffiliated
          third parties for marketing purposes.
        </p>

        <h2>Collective Data</h2>
        <p>
          Occasionally, we may collect statistical and non-personal information
          about the use of the site. This information may include how many
          visitors visit a specific page on the site, how long they stay there,
          and which hyperlinks they visit. We collect this information to
          determine which areas of the site are most popular and where to
          enhance the site for our visitors. This collective data may be used to
          describe the use of our site to third parties or in response to a
          government request. However, this data will not personally identify
          you or any other of our website visitors.
        </p>

        <h2>Links to Other Sites</h2>
        <p>
          While visiting our site, it is possible that you could be directed
          (via hyperlink or other means) to other sites that are outside of our
          control. These other websites may send their own cookies to visitors,
          collect data, or solicit your personal information. It is important
          that you read the privacy policies of these other sites before using
          them. We are not responsible for the privacy practices of other
          websites and cannot guarantee the security of any personal information
          that is collected on those other sites.
        </p>

        <h2>Contact Us</h2>
        <p>
          We reserve the right to make changes to our privacy policy. Any
          changes to this privacy policy will be posted here. If you have any
          questions or concerns about our Privacy Policy, please contact us at:
        </p>
        <p>
          6024 E. 32nd St.
          <br />
          Yuma, AZ 85365
        </p>
        <p>(928) 783-4575</p>
      </div>
    </Page>
  );
}

export default PrivacyPolicy;
