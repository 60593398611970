import React from "react";

import { ReactComponent as MapIcon } from "../../assets/location-icon.svg";

import "../../styles/components/common/address.scss";

function Address({ darkmode }) {
  const modeClass = darkmode ? "white" : "";

  return (
    <div
      className="address"
      onClick={() => window.open("https://g.page/DeasonGarnerLaw?share")}
    >
      <MapIcon className={modeClass} />
      <div>
        <p className={modeClass}>6024 E. 32nd St.</p>
        <p className={modeClass}>Yuma, AZ 85365</p>
      </div>
    </div>
  );
}

export default Address;
