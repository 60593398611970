import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function FamilyOwnedBusiness() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="familyOwnedBusiness"
      className="generic-page"
      title="Family-Owned Business"
      description="Estates involving family-owned businesses may have unique needs. Our attorneys can help you determine the next steps"
    >
      <div className="page-content">
        <h1>Family-Owned Business</h1>
        <p className="subheader">
          A Family-Owned Business attorney can protect your business, ranch, or
          farm
        </p>
        <p>
          If you are a farmer, rancher or owner of a family business in Yuma,
          Arizona, your estate plan will have to address some potentially
          challenging issues that many other people don't have to think about.
          For many people, the family business, farm or ranch is not only the
          way they make their living, but represents an important part of their
          heritage and who they are.
        </p>
        <p>
          Unfortunately, the statistics surrounding family farms and businesses
          don't bode well for people who want to leave them to the next
          generation. The numbers say that about 70% of all family-run
          businesses in the country don't stay in the family longer than a
          single generation.
        </p>
        <p>
          Fortunately, if you have a family-owned business, farm, or ranch,
          there are steps you can take to plan for transition or succession
          issues that will take place after you die or leave the business.
        </p>

        <h2>Family-Owned Businesses</h2>
        <p>
          Whether your family-owned business is a ranch, farm, small business,
          large business or anything else, determining what you want to happen
          to it after you are gone is a vital step to take as you go about the
          estate planning process.
        </p>
        <ul>
          <li>
            Succession Planning.{" "}
            <span>
              Do you want to pass your family business on to the next
              generation? If so, what you have to do to make sure the transition
              goes smoothly? Who will run the business? Who will step in if you
              become incapacitated? Business succession plans have to address
              all of these questions, and more.
            </span>
          </li>
          <li className="mt-3">
            Closing Your Business.{" "}
            <span>
              If you don't want to pass your business on to the next generation,
              or you don't believe anyone is willing to take it over, what
              options are you left with? If you plan on selling, what is the
              best way of going about doing this? Where do you start looking for
              buyers? Do you take the proceeds and use them as inheritances?
              Even if closing or selling your business is not what you want to
              do, your plan should be prepared for the possibility that there
              might not be any other option.
            </span>
          </li>
        </ul>

        <h2>Contact Us for Advice</h2>
        <p>
          Deason Garner & Hansen Law Firm has helped family-owned business
          owners confront their transition and planning questions for years.
          Whether you are crafting an estate plan that assumes you will sell the
          business and distribute the proceeds as inheritances, or want to
          ensure that your family business will stay in the family for as long
          as possible, we can help you. However, to give you the advice and
          guidance you need, it's up to you to take the first step. Start today
          by contacting us at (928) 783-4575 and scheduling an appointment with
          Shawn D. Garner or Adam D. Hansen, our experienced attorneys, so we
          can discuss your concerns. Once we know the details of your
          circumstances and understand what your long-term goals are, we can
          help you create a plan that fits your needs.
        </p>
      </div>
    </Page>
  );
}

export default FamilyOwnedBusiness;
