import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";

import Page from "../../common/page";

import "../../../styles/components/pages/generic-page.scss";

function BusinessPlanning() {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
    ReactGA4.send("pageview");
  }, []);

  return (
    <Page
      selectedTab="estatePlanning"
      selectedLevel2="businessPlanning"
      className="generic-page"
      title="Business Planning"
      description="Our attorneys can help you navigate the many legal challenges of starting a new business"
    >
      <div className="page-content">
        <h1>Business Planning</h1>
        <p className="subheader">
          A business planning attorney can assist with business information
        </p>
        <p>
          If you are starting a business, you could face many challenges,
          including finding customers, building a prototype, assembling a team
          and raising capital, to name a few. Preparation and planning are key
          to achieving your business goals. Business planning attorneys and the
          skilled legal minds at Deason Garner & Hansen Law Firm help
          entrepreneurs with all of their business planning needs. We understand
          the challenges you face in the formation, growth and disposition of
          your company, and we can offer legal guidance every step of the way.
        </p>

        <h2>Why Businesses Require Legal Counsel</h2>
        <p>
          From securing copyrights and trademarks to handling lawsuits and
          liability, a business planning attorney can help in numerous ways,
          including:
        </p>
        <ul>
          <li>
            Formation{" "}
            <span>
              - Evaluating and inputting business plans, growth objectives and
              financial strategies and protecting intellectual property
            </span>
          </li>
          <li>
            Growth{" "}
            <span>
              - Planning and negotiating joint ventures and other strategic
              alliances; providing legal advice regarding organizational
              structure and risk management, franchising strategies, strategic
              acquisitions, and debt and equity financing strategies
            </span>
          </li>
          <li>
            Disposition{" "}
            <span>
              - Succession planning to smooth the transition of the business to
              an employee, partner or family member; negotiating buy/sell
              agreements; and planning external exit strategies
            </span>
          </li>
        </ul>

        <h2>Areas in Which a Business Planning Attorney Can Help</h2>
        <p>
          A business planning attorney can assist you in many areas of your
          business, including:
        </p>
        <ul>
          <li>
            Strategic Planning{" "}
            <span>
              - How to grow the business, protect intellectual property,
              mitigate risk and implement financial strategies
            </span>
          </li>
          <li>
            Financial Feasibility{" "}
            <span>
              - Start-up capital, revenues, expenses, investor income and
              disbursements
            </span>
          </li>
          <li>
            Business Plans{" "}
            <span>- Defining the company profile and its goals</span>
          </li>
          <li>
            Business Entity Formation{" "}
            <span>
              - The form of business (e.g., LLC) will determine which income tax
              return to file
            </span>
          </li>
          <li>
            Employment Law{" "}
            <span>- Understanding the employer/employee relationship</span>
          </li>
          <li>
            New Venture Financing{" "}
            <span>- Private equity capital to fund your startup company</span>
          </li>
          <li>
            Investor Relations{" "}
            <span>
              - Establishing and enabling effective communication between the
              company and other constituencies
            </span>
          </li>
          <li>
            Contracts{" "}
            <span>
              - Crafting insurance, hiring and firing, and intellectual property
              contracts
            </span>
          </li>
          <li>
            Commercial Lease Review{" "}
            <span>
              - Making certain that terms are correctly stated and that
              renewals, contracts and expansion rights are covered
            </span>
          </li>
          <li>
            Structural Matters{" "}
            <span>
              - Understanding C-corporations, S-corporations, Limited Liability
              Company (LLC), Limited Liability Partnership (LLP), partnerships,
              joint ventures and sole proprietorships, and which to choose
            </span>
          </li>
        </ul>

        <h2>Legal and Capital Protections in Business Formation</h2>
        <p>
          Forming an effective business plan can protect your profits, shield
          you from liabilities, and foster a group of faithful employees.
          Choosing the right structure between C-corporation, S-corporation,
          LLC, LLP, partnership, joint venture and sole proprietorship is
          paramount. Each formation offers different options, benefits and
          risks. Our team is here to help you understand your options and offer
          proactive solutions to minimize your risk concerning capital, business
          disputes, liability, compliance issues and more.
        </p>

        <h2>
          Why You Should Seek Legal Counsel from Deason Garner & Hansen Law Firm
        </h2>
        <p>
          When it comes to your new business, no aspect of its creation can be
          overlooked. Having a team of business planning attorneys can help
          ensure that when it comes to strategic planning, business entity
          formation, contract formation, investor relations, growth and
          disposition, you will have the help of someone who not only has your
          best interests in mind, but also has a wealth of experience in
          handling such matters. Over the course of three decades, Deason Garner
          & Hansen Law Firm has grown to become Yuma's premier group of business
          planning attorneys. We know how to anticipate potential areas of
          difficulty and protect you from problems before they happen.
        </p>

        <h2>Protect Yourself and Your Business</h2>
        <p>
          If you are starting a new business, you could face many challenges. Do
          not face them alone. Contact our team to protect yourself and your
          business. Visit us online or call (928) 783-4575 today to learn more
          or to schedule a consultation with one of our attorneys, Shawn D.
          Garner or Adam D. Hansen.
        </p>
      </div>
    </Page>
  );
}

export default BusinessPlanning;
